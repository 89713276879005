import React from 'react';
import styled from 'styled-components';
import { FcCalendar } from 'react-icons/fc';
import { MdLocationOn } from 'react-icons/md';
import { Edit } from '@mui/icons-material';
import { useHistory } from 'react-router';

import { Routes } from 'constants/routes';
import { Colors, StatusColors } from 'constants/style/Colors';

import { InternshipDocument } from 'models/Internship';
import IconButton from 'components/buttons/IconButton';
import { ScreenSize } from 'constants/responsive/MediaQueries';

const StyledContainer = styled.div`
  width: 23%;
  float: left;
  height: 260px;
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${Colors.White};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.01);
  margin-bottom: 24px;
  margin-right: 1%;
  margin-left: 1%;
  align-self: flex-start;
  justify-self: flex-start;
  transition: all 0.4s;

  ${ScreenSize.large} {
    width: 31.33%;
  }

  ${ScreenSize.medium} {
    width: 48%;
  }

  ${ScreenSize.mediumSmall} {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Category = styled.span`
  color: ${Colors.Grey};
  background-color: ${Colors.PrimaryBackground};
  font-size: 14px;
  padding: 4px 16px;
  border-radius: 8px;
  display: inline-block;
  flex-shrink: 1;
`;

const Title = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 8px;
  color: ${Colors.Black};
  transition: color 0.4s;
  cursor: pointer;

  &:hover {
    color: ${Colors.Primary};
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const SpacedRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const GreyText = styled.p`
  margin: 0;
  margin-left: 8px;
  font-size: 14px;
  color: ${Colors.Grey};
`;

const Space = styled.div`
  flex: 1;
`;

const ApplicantsCard = styled.span<{ bg: string; color: string }>`
  font-size: 14px;
  color: ${(props) => props.color};
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  padding: 4px 8px;
  margin-right: 8px;
`;

interface Props {
  internship: InternshipDocument;
  onClick: () => void;
}

export const InternshipDashboardCard = ({ internship, onClick }: Props) => {
  const { title, domain, maxPositions, applicants, acceptedApplicants, startDate, endDate, location } =
    internship;
  const history = useHistory();

  return (
    <StyledContainer>
      <SpacedRow>
        <Category>{domain}</Category>
        <IconButton
          icon={<Edit />}
          onClick={(e: any) => {
            e.preventDefault();
            history.push(`${Routes.DashboardAddInternship}/?id=${internship._id}`);
          }}
          color={'primary'}
        />
      </SpacedRow>
      <Title onClick={onClick}>{title}</Title>
      <Row>
        <FcCalendar size={20} />
        <GreyText>
          {`${new Date(startDate).toLocaleDateString('ro-RO')} - 
          ${new Date(endDate).toLocaleDateString('ro-RO')}`}
        </GreyText>
      </Row>
      <Row>
        <MdLocationOn color={Colors.Danger} size={20} />
        <GreyText>{location}</GreyText>
      </Row>
      <Space />
      <Row>
        <ApplicantsCard color={StatusColors.Success} bg={StatusColors.SuccessBg}>
          {acceptedApplicants.length} aplicanti
        </ApplicantsCard>
        <ApplicantsCard color={StatusColors.Warning} bg={StatusColors.WarningBg}>
          {applicants.length} aplicanti
        </ApplicantsCard>
        <ApplicantsCard color={Colors.Grey} bg={Colors.PrimaryBackground}>
          {maxPositions} pozitii
        </ApplicantsCard>
      </Row>
    </StyledContainer>
  );
};
