import { makeAutoObservable, runInAction } from 'mobx';
import { ReviewApi } from 'api/ReviewApi';
import { ReviewDocument, ReviewDto } from 'models/Review';
import { INITIALCV } from 'constants/initial/InitialCv';
import { INITIAL_COMPANY } from 'constants/initial/InitialCompany';
import { INITIAL_INTERNSHIP } from 'constants/initial/Internship';

export class ReviewStore {
  api: ReviewApi;
  loading: boolean = false;

  current: ReviewDocument = {
    _id: '',
    studentId: 0,
    cvId: INITIALCV,
    review: '',
    companyId: INITIAL_COMPANY,
    internshipId: INITIAL_INTERNSHIP,
    courseId: 0,
  };
  reviews: ReviewDocument[] = [];

  constructor(api: ReviewApi) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.api = api;
  }

  async add(data: ReviewDto) {
    this.loading = true;
    const doc = await this.api.add(data);

    runInAction(() => {
      this.loading = false;
    });

    return doc;
  }
  async getOne(id: string) {
    this.loading = true;
    const res = await this.api.getOne(id);
    runInAction(() => {
      this.loading = false;
      this.current = res.data;
    });
    return res;
  }
  async listByStudentId(id: number) {
    this.loading = true;
    const res = await this.api.listByStudentId(id);
    console.log(res);
    runInAction(() => {
      this.loading = false;
      this.reviews = res.data;
    });
    return res;
  }
  async listByCompany(id: string, studentId: number) {
    this.loading = true;
    const res = await this.api.listByCompany(id, studentId);
    runInAction(() => {
      this.loading = false;
      this.reviews = res.data;
    });
    return res;
  }
  async listByInternship(id: string, studentId: number) {
    this.loading = true;
    const res = await this.api.listByInternship(id, studentId);
    runInAction(() => {
      this.loading = false;
      this.current = res.data[0];
    });
    return res;
  }
  async listByCourse(id: number) {
    this.loading = true;
    const res = await this.api.listByCourse(id);
    console.log(res);
    runInAction(() => {
      this.loading = false;
      this.reviews = res.data;
    });
    return res;
  }
}
