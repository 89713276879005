import { makeAutoObservable, runInAction } from 'mobx';
import { InternshipApi } from '../api/InternshipApi';

import { INITIAL_INTERNSHIP } from 'constants/initial/Internship';
import { InternshipDocument, InternshipDto } from '../models/Internship';
import { CvDocument } from 'models/CV';

export class InternshipStore {
  api: InternshipApi;
  loading: boolean = false;

  current: InternshipDocument = INITIAL_INTERNSHIP;
  internships: InternshipDocument[] = [];

  constructor(api: InternshipApi) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.api = api;
  }

  async add(data: InternshipDto) {
    this.loading = true;
    const doc = await this.api.add(data);

    runInAction(() => {
      this.loading = false;
    });

    return doc;
  }

  async getOne(id: string) {
    this.loading = true;
    const res = await this.api.getOne(id);
    runInAction(() => {
      this.loading = false;
      this.current = res.data;
    });
    return res;
  }

  async listByCompany(id: string) {
    this.loading = true;
    const res = await this.api.listByCompany(id);
    runInAction(() => {
      this.loading = false;
      this.internships = res.data;
    });
    return res;
  }

  async listByCV(id: string) {
    this.loading = true;
    const res = await this.api.listByCV(id);
    console.log('In store', res);
    runInAction(() => {
      this.loading = false;
      this.internships = res.data;
    });
    return res;
  }

  async list() {
    this.loading = true;
    const res = await this.api.list();
    console.log(res);
    runInAction(() => {
      this.loading = false;
      this.internships = res.data;
    });
    console.log(this.internships);
    return res;
  }

  async update(id: string, data: Omit<InternshipDto, 'companyId'>) {
    this.loading = true;
    const res = await this.api.update(id, data);
    runInAction(() => {
      this.loading = false;
      this.current = res.data;
    });
    return res;
  }

  async addApplicant(id: string, applicantId: string) {
    this.loading = true;
    const res = await this.api.addApplicant(id, applicantId);
    runInAction(() => {
      this.loading = false;
    });
    return res;
  }

  async acceptApplicant(id: string, applicantId: CvDocument) {
    this.loading = true;
    const res = await this.api.acceptApplicant(id, applicantId._id);
    runInAction(() => {
      this.loading = false;
      const index = this.current.applicants.indexOf(applicantId);
      this.current.applicants.splice(index, 1);
      this.current.acceptedApplicants.push(applicantId);
    });
    return res;
  }

  async declineApplicant(id: string, applicantId: CvDocument) {
    this.loading = true;
    const res = await this.api.declineApplicant(id, applicantId._id);
    runInAction(() => {
      this.loading = false;
      const index = this.current.applicants.indexOf(applicantId);
      this.current.applicants.splice(index, 1);
      this.current.declinedApplicants.push(applicantId);
    });
    return res;
  }
}
