/* eslint-disable */
import React from 'react';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { Colors } from 'constants/style/Colors';

const useStyles = makeStyles({
  root: {
    color: Colors.White,
    textTransform: 'uppercase',
    fontFamily: 'Poppins',
    padding: '12px 32px',
    weight: '500',
    height: 56,
    fontSize: '16px',
    lineHeight: '1.5',
    border: 'none',
    outline: 'none',
    transition:
      'color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
  },

  outlined: {
    backgroundColor: '#f5f8fa',
    color: '#7e8299',
    border: 'none',
    padding: '12px 16px',
    transition: 'all 0.4s',
    outline: 'none',
  },
});

interface ButtonProps {
  children: any;
  full?: boolean;
  startIcon?: any;
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained' | undefined;
  type?: undefined | 'submit';
}

const PrimaryButton = ({ children, onClick, variant, full, startIcon, type }: ButtonProps) => {
  const classes = useStyles({ variant });
  return (
    <Button
      startIcon={startIcon}
      onClick={onClick}
      variant={variant}
      className={clsx([classes.root, variant === 'outlined' && classes.outlined])}
      type={type}
      fullWidth={full}
    >
      {children}
    </Button>
  );
};

PrimaryButton.defaultProps = {
  variant: 'contained',
  type: undefined,
};

export default PrimaryButton;
