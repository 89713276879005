import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { experimentalStyled as MUIstyled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';

import { Routes } from '../../constants/routes';
import { ScreenSize } from 'constants/responsive/MediaQueries';

import { AppBar } from 'components/navigation/AppBar';
import { Footer } from 'components/footer/Footer';
import { MyInternshipCard } from '../../components/internship/myInternships/MyInternshipCard';
import { useStore } from 'hooks/useStore';
import { InternshipDocument } from 'models/Internship';
import { Colors } from '../../constants/style/Colors';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const Col = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ScreenSize.mediumSmall} {
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const Spacer = styled.div`
  height: 10%;
`;
const Row = styled.div`
  margin-top: 32px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const StyledPaper = MUIstyled(Paper)`
  padding: 0px 32px 0px 32px;
  height: 90vh;
  overflow: auto;
  box-shadow: none;
  ${ScreenSize.mediumSmall} {
    padding: 0px 0px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 0;
`;

const Subtitle = styled.p`
  font-size: 16px;
  color: ${Colors.Grey};
`;

const MyInternshipsScreen = () => {
  const [internshipsList, setInternships] = useState<InternshipDocument[]>([]);
  const history = useHistory();
  const {
    cvStore,
    internshipStore: { internships, listByCV },
  } = useStore();

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      (async () => {
        const resp = await cvStore.getByStudentId(user.student.id);
        console.log(resp);
        if (resp.data.length) {
          listByCV(resp.data[0]._id);
          setInternships(internships);
        }
      })();
    }
  }, []);

  useEffect(() => {
    setInternships(internships);
  }, [internships]);

  return (
    <>
      <AppBar />
      {internshipsList.length ? (
        <StyledPaper>
          <Spacer />
          <Row>
            <Col>
              {internshipsList.map((internship: InternshipDocument) => (
                <MyInternshipCard
                  title={internship.title}
                  company={internship.companyId.name}
                  description={internship.companyId.description}
                  logo={internship.companyId.logoUrl}
                  location={internship.location}
                  domain={internship.domain}
                  to={'my_internship/' + internship._id}
                  key={internship._id}
                />
              ))}
            </Col>
          </Row>
        </StyledPaper>
      ) : (
        <StyledContainer>
          <Title>Nici un rezultat</Title>
          <Subtitle>Nu ati aplicat la nici un program de practica.</Subtitle>
          <PrimaryButton
            onClick={() => {
              history.push(Routes.Home);
            }}
          >
            aplica acum
          </PrimaryButton>
        </StyledContainer>
      )}
      <Footer />
    </>
  );
};

export default observer(MyInternshipsScreen);
