import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { Routes } from '../../../constants/routes';
import { Groups } from '../../../constants/enums/Groups';

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

export const DashboardHomeScreen = () => {
  const history = useHistory();

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      if (user.user_groups.includes(Groups.Company)) return history.push(Routes.DashboardInternships);
      if (user.user_groups.includes(Groups.Dean)) return history.push(Routes.DashboardCompanies);
      if (user.user_groups.includes(Groups.Professor)) return history.push(Routes.DashboardStudentsList);
    } else {
      history.push(Routes.Login);
    }
  }, []);

  return <StyledContainer />;
};
