import axios, { Method, AxiosRequestConfig } from 'axios';

export class ApiConfig {
  api: string;
  appVersion: string;

  constructor(api: string, releaseVersion: string) {
    this.api = api;
    this.appVersion = releaseVersion;
  }

  async sendRequest(method: Method, endpoint: string, d?: any) {
    const url = `${this.api}${endpoint}`;

    const config: AxiosRequestConfig = {
      method,
      url,
      data: d,
    };

    const { data, status } = await axios(config);
    return { data, status };
  }
}
