import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { Filters } from 'constants/style/Colors';
import { useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { MyInternshipDetails } from 'components/internship/myInternships/MyInternshipDetails';
import { AppBar } from 'components/navigation/AppBar';
import { Footer } from 'components/footer/Footer';
import { useStore } from 'hooks/useStore';

import { FileUpload } from 'components/internship/FileUpload';

const IconTextGroup = styled.div`
  display: flex;
  align-items: center;
`;

const SpacerD = styled.div`
  height: 32px;
`;

const Spacer = styled.div`
  height: 32px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ebebeb;
`;

const StyledFileIcon = styled(InsertDriveFileTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Blue};
`;

const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ParamsType {
  id: string;
}

const MyInternshipScreen = () => {
  const [notebook, setNotebook] = useState<any>();
  const [report, setReport] = useState<any>();
  const [project, setProject] = useState<any>();
  const [certificate, setCertificate] = useState<any>();
  const [courseId, setCourseId] = useState<number>(0);
  const { id } = useParams<ParamsType>();
  const hiddenFileInput = React.useRef(null);
  const hiddenProjectInput = React.useRef(null);
  const hiddenReportInput = React.useRef(null);

  const {
    internshipStore: { getOne, current },
    cvStore: { getByStudentId },
    studentFileStore: { listByCvId },
    applicantFileStore,
  } = useStore();

  const getCourseDescription = () => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      return user.cursuri[0].descriere_curs;
    }
  };

  useEffect(() => {
    (async () => {
      const lsUser = localStorage.getItem('user');
      if (lsUser) {
        const user = JSON.parse(lsUser);
        setCourseId(parseInt(user.cursuri[0].curs));
        getOne(id);
        const cvResponse = await getByStudentId(parseInt(user.student.id));
        const filesResponse = await listByCvId(cvResponse.data._id);
        filesResponse.data.forEach((f: any) => {
          if (f.type === 'report') setReport({ name: f.filename, id: f.fileId });
          if (f.type === 'project') setProject({ name: f.filename, id: f.fileId });
          if (f.type === 'notebook') setNotebook({ name: f.filename, id: f.fileId });
        });
        const certificateResponse = await applicantFileStore.listByCvId(cvResponse.data._id);
        setCertificate({
          name: certificateResponse.data[certificateResponse.data.length - 1].filename,
          id: certificateResponse.data[certificateResponse.data.length - 1].fileId,
        });
      }
    })();
  }, []);

  const handleButtonClick = () => {
    /* @ts-ignore */
    hiddenFileInput!.current.click();
  };

  const handleProjectButtonClick = () => {
    /* @ts-ignore */
    hiddenProjectInput!.current.click();
  };

  const handleReportButtonClick = () => {
    /* @ts-ignore */
    hiddenReportInput!.current.click();
  };

  return (
    <CenterContainer>
      <AppBar />
      <MyInternshipDetails
        title={current.title}
        location={current.location}
        domain={current.domain}
        company={current.companyId.name}
      >
        <h2>Descrierea programului</h2>
        <Spacer />
        <div dangerouslySetInnerHTML={{ __html: getCourseDescription() }} />
        <SpacerD />
        <h2>Adeverinta de practica</h2>
        <Spacer />
        <IconTextGroup>
          <StyledFileIcon fontSize="medium" />
          {certificate && (
            <a
              href={`${process.env.REACT_APP_API_URL}/file/download/${certificate.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {certificate.name || ''}
            </a>
          )}
        </IconTextGroup>
        <SpacerD />

        <FileUpload
          title="Raport de practica"
          uploadButtonText="RAPORTUL DE PRACTICA"
          hiddenInput={hiddenReportInput}
          file={report}
          internshipId={id}
          courseId={courseId}
          studentFileType="report"
          handleButtonClick={handleReportButtonClick}
          setFile={setReport}
        />

        <FileUpload
          title="Proiect de practica"
          uploadButtonText="PROIECTUL DE PRACTICA"
          hiddenInput={hiddenProjectInput}
          file={project}
          internshipId={id}
          courseId={courseId}
          studentFileType="project"
          handleButtonClick={handleProjectButtonClick}
          setFile={setProject}
        />

        <FileUpload
          title="Caiet de practica"
          uploadButtonText="CAIETUL DE PRACTICA"
          hiddenInput={hiddenFileInput}
          file={notebook}
          internshipId={id}
          courseId={courseId}
          studentFileType="notebook"
          handleButtonClick={handleButtonClick}
          setFile={setNotebook}
        />
      </MyInternshipDetails>
      <Footer />
    </CenterContainer>
  );
};

export default observer(MyInternshipScreen);
