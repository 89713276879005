import * as Yup from 'yup';

export const RegistrationSchema = Yup.object({
  name: Yup.string().required('Camp obligatoriu'),
  address: Yup.string().required('Camp obligatoriu'),
  email: Yup.string().email('Email invalid.').required('Camp obligatoriu.'),
  phone: Yup.string().required('Camp obligatoriu.'),
  fiscalCode: Yup.string().required('Camp obligatoriu.'),
  registrationCode: Yup.string().required('Camp obligatoriu.'),
  established: Yup.string().required('Camp olbligatoriu.'),
  websiteUrl: Yup.string().required('Camp obligatoriu.'),
  password: Yup.string()
    .required('Camp obligatoriu.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      'Parola trebuie sa contina, min 8 caractere, o litera mare, o litera mica si un caracter special'
    ),
  confirmPassword: Yup.string()
    .required('Camp obligatoriu')
    .oneOf([Yup.ref('password'), null], 'Parolele trebuie sa coincida!'),
});
