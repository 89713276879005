import { CVHelpApi } from 'api/CVHelpApi';
import autoBind from 'auto-bind';
import { makeAutoObservable, runInAction } from 'mobx';
import { CvHelpDocument, CvHelpDto } from 'models/CVHelp';
import { INITIALCVHELP } from 'constants/initial/InitialCvHelp';

export class CVHelpStore {
  api: CVHelpApi;
  students: any[] = [];
  current: CvHelpDocument | undefined = INITIALCVHELP;
  loading: boolean = false;

  constructor(api: CVHelpApi) {
    makeAutoObservable(this);
    autoBind(this);

    this.api = api;
  }

  async add(data: CvHelpDto) {
    this.loading = true;
    const doc = await this.api.add(data);

    runInAction(() => {
      this.loading = false;
    });

    return doc;
  }
  async listByStudentId(studentId: number) {
    this.loading = true;
    const res = await this.api.listByStudentId(studentId);
    runInAction(() => {
      this.loading = false;
      if (res.data === '') this.current = undefined;
      else this.current = res.data;
    });
    return res;
  }
  async listByCourseId(courseId: number) {
    this.loading = true;
    const res = await this.api.listByCourseId(courseId);
    runInAction(() => {
      this.loading = false;
      this.students = res.data;
    });
    return res;
  }
}
