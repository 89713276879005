import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';

import { Filters } from 'constants/style/Colors';
import { StatusColors } from 'constants/style/Colors';

const StyledContainer = styled.div`
  width: 20%;
  padding: 16px;
  background-color: #f6fafd;
  position: fixed;
  top: 160px;
  right: 48px;
  align-text: center;
`;

const Row = styled.div`
  display: flex;
`;

const SuccessTypography = styled.p`
  color: ${StatusColors.Success};
  margin: 0px;
`;

const CheckIcon = styled(CheckCircleTwoToneIcon)`
  filter: ${Filters.Success};
`;

interface Props {
  sent: boolean;
  handleSent: () => void;
}

export const CVHelpForm = ({ sent, handleSent }: Props) => {
  return (
    <StyledContainer>
      <p>Ai nevoie de consultanță din partea biroului de orientare în carieră?</p>
      {sent ? (
        <Row>
          <CheckIcon />
          <SuccessTypography>
            Ai solicitat consultanță pentru intocmirea CV-ului. O persoană din partea biroului de orientare în
            carieră vă va contacta
          </SuccessTypography>
        </Row>
      ) : (
        <Button variant="outlined" onClick={handleSent}>
          Da, doresc ajutor
        </Button>
      )}
    </StyledContainer>
  );
};
