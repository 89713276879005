import { UserRoles } from '../enums/UserRoles';

export enum CompanyStatus {
  Approved = 'approved',
  Pending = 'pending',
  Declined = 'declined',
}

export const INITIAL_INTERNSHIP = {
  _id: '',
  companyId: {
    _id: '',
    name: '',
    address: '',
    fiscalCode: '',
    registrationCode: '',
    established: '',
    email: '',
    phone: '',
    description: '',
    websiteUrl: '',
    contacts: [],
    password: '',
    status: CompanyStatus.Pending,
    logoUrl: '',
    role: UserRoles.Company,
    faculties: [],
  },
  title: '',
  domain: '',
  maxPositions: '',
  description: '',
  startDate: new Date(),
  endDate: new Date(),
  applicants: [],
  acceptedApplicants: [],
  declinedApplicants: [],
  createdAt: new Date(),
  updatedAt: new Date(),
  location: '',
};
