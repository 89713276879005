import { ApiConfig } from './ApiConfig';

import { CompanyModel } from '../models/Company';

export class CompanyApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  create(data: Omit<CompanyModel, 'role'>) {
    return this.apiConfig.sendRequest('POST', '/company', data);
  }

  list() {
    return this.apiConfig.sendRequest('GET', '/company', null);
  }

  getOne(id: string) {
    return this.apiConfig.sendRequest('GET', `/company/${id}`, null);
  }

  update(id: string, data: Partial<CompanyModel>) {
    return this.apiConfig.sendRequest('PATCH', `/company/${id}`, data);
  }
}
