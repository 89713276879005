import React from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    transition: 'background-color .4s',
  },

  cell: {
    border: 'none',
  },
});

interface Props {
  name: string;
  studentId: number;
  email: string;
  major: string;
}

const StudentHelpRow = ({ name, studentId, email, major }: Props) => {
  const classes = useStyles();

  return (
    <>
      <TableRow className={`${clsx(classes.root)}`}>
        <TableCell className={classes.cell} align={'left'}>
          {name}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {studentId}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {major}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {email}
        </TableCell>
      </TableRow>
    </>
  );
};

export default observer(StudentHelpRow);
