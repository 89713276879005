import React from 'react';
import styled from 'styled-components';

import { StatusColors } from 'constants/style/Colors';

import { CompanyStatuses } from 'constants/enums/CompanyStatuses';

const StyledContainer = styled.div<{ background: string; color: string }>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.background};
  border-radius: 8px;
  padding: 4px 8px;
  width: 120px;
  text-transform: uppercase;
  text-align: center;
`;

interface Props {
  status: 'approved' | 'pending' | 'declined';
}

export const CompanyStatus = ({ status }: Props) => {
  const getColor = () => {
    switch (status) {
      case CompanyStatuses.Accepted:
        return { color: StatusColors.Success, background: StatusColors.SuccessBg };
      case CompanyStatuses.Pending:
        return { color: StatusColors.Warning, background: StatusColors.WarningBg };
      default:
        return { color: StatusColors.Danger, background: StatusColors.DangerBg };
    }
  };

  const getStatus = () => {
    switch (status) {
      case CompanyStatuses.Accepted:
        return 'acceptată';
      case CompanyStatuses.Pending:
        return 'în așteptare';
      default:
        return 'respinsă';
    }
  };

  return (
    <StyledContainer color={getColor().color} background={getColor().background}>
      {getStatus()}
    </StyledContainer>
  );
};
