import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { Routes } from '../../constants/routes';
import { ScreenSize } from '../../constants/responsive/MediaQueries';

import { Spacer } from '../spacer/Spacer';
import { useStore } from '../../hooks/useStore';
import { TextInput } from '../control/TextInput';
import PrimaryButton from '../buttons/PrimaryButton';

export const StyledFormContainer = styled.div`
  width: 80%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  min-width: 50%;

  ${ScreenSize.mediumSmall} {
    width: 100%;
  }
`;

function LoginForm() {
  const [enteredUsername, setEnteredUsername] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const history = useHistory();

  const {
    authStore: { login },
  } = useStore();

  const enterUsernameHandler = (event: any) => {
    setEnteredUsername(event.target.value);
  };

  const enterPasswordHandler = (event: any) => {
    setEnteredPassword(event.target.value);
  };

  const onSubmit = async () => {
    try {
      const res = await login(enteredUsername, enteredPassword);
      if (res.status === 201) history.push(Routes.Dashboard);
    } catch (e: any) {
      toast.error('Autentificare eșuată! Reintroduceți câmpurile pentru a încerca din nou.', {
        position: 'top-center',
      });
    }
  };

  return (
    <>
      <StyledFormContainer>
        <TextInput value={enteredUsername} onChange={enterUsernameHandler} label="Utilizator" />
        <Spacer height={16} />
        <TextInput value={enteredPassword} onChange={enterPasswordHandler} label="Parola" type="password" />
        <Spacer height={16} />

        <PrimaryButton full onClick={() => console.log(enteredUsername)}>
          LOGIN
        </PrimaryButton>
        <Spacer height={16} />

        <PrimaryButton full variant={'outlined'} onClick={onSubmit}>
          autentificare AAME
        </PrimaryButton>
      </StyledFormContainer>
    </>
  );
}

export default observer(LoginForm);
