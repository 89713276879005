import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router';

import { Colors } from 'constants/style/Colors';

import { useStore } from '../../../hooks/useStore';
import { CompanyProfile } from '../../../components/profile/CompanyProfile';
import { Spacer } from 'components/spacer/Spacer';
import { Routes } from 'constants/routes';
import AppTable from 'components/table/AppTable';
import ApplicantRow from 'components/rows/ApplicantRow';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1800px;
  margin: auto;
`;

const WhiteContainer = styled.div`
  padding: 24px;
  border-radius: 8px;
  background-color: ${Colors.White};
`;

const TableColums = [
  { name: 'Nume', width: 20 },
  { name: 'Programul de studiu', width: 20 },
  { name: 'Anul de studiu', width: 20 },
  { name: 'Pozitia', width: 20 },
  { name: 'CV ', width: 20 },
  { name: 'Status ', width: 20 },
  { name: 'Actiuni ', width: 20, align: 'center' },
];
enum CompanyStatuses {
  Accepted = 'approved',
  Pending = 'pending',
  Declined = 'declined',
}

const DashboardInternshipDetails = () => {
  const {
    authStore: { user },
    internshipStore: { getOne, current },
  } = useStore();

  const history = useHistory();
  const params = useParams() as { id: string };

  useEffect(() => {
    (async () => {
      const res = await getOne(params.id);
      console.log('applicants', res.data.applicants);
    })();
  }, []);

  return (
    <StyledContainer>
      <CompanyProfile
        action={{
          title: 'Adauga ',
          icon: <AiOutlinePlus color={Colors.White} />,
          handler: () => {
            history.push(Routes.DashboardAddInternship);
          },
        }}
        logoUrl={user ? user.logoUrl : ''}
        address={user ? user.address : ''}
        companyName={user ? user.name : ''}
        email={user ? user.email : ''}
      />
      <Spacer height={32} />
      <WhiteContainer>
        <AppTable tableHeaderColumnNames={TableColums}>
          {current.applicants.map((applicant: any, index: number) => (
            <ApplicantRow
              companyId={current.companyId._id}
              key={index + 'applicant'}
              cv={applicant}
              internshipId={current._id}
              internshipTitle={current.title}
              status={CompanyStatuses.Pending}
            />
          ))}
          {current.acceptedApplicants.map((applicant: any, index: number) => (
            <ApplicantRow
              companyId={current.companyId._id}
              key={index + 'accepted'}
              cv={applicant}
              internshipId={current._id}
              internshipTitle={current.title}
              status={CompanyStatuses.Accepted}
            />
          ))}
          {current.declinedApplicants.map((applicant: any, index: number) => (
            <ApplicantRow
              companyId={current.companyId._id}
              key={index + 'declined'}
              cv={applicant}
              internshipId={current._id}
              internshipTitle={current.title}
              status={CompanyStatuses.Declined}
            />
          ))}
        </AppTable>
      </WhiteContainer>
    </StyledContainer>
  );
};

export default observer(DashboardInternshipDetails);
