import { ApiConfig } from './ApiConfig';
import { ReviewDto } from 'models/Review';

const DEFAULT_ENDPOINT = '/review';

export class ReviewApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  add(data: ReviewDto) {
    return this.apiConfig.sendRequest('POST', DEFAULT_ENDPOINT, data);
  }
  getOne(id: string) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/${id}`, null);
  }
  listByStudentId(id: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/student/${id}`, null);
  }

  listByCompany(id: string, studentId: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/student/${studentId}/company/${id}`, null);
  }

  listByInternship(id: string, studentId: number) {
    return this.apiConfig.sendRequest(
      'GET',
      `${DEFAULT_ENDPOINT}/student/${studentId}/internship/${id}`,
      null
    );
  }

  listByCourse(id: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/course/${id}`, null);
  }
}
