import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { useQuery } from 'hooks/useQuery';
import { useStore } from '../../../hooks/useStore';
import { Spacer } from '../../../components/common/Spacer';
import { CompanyProfile } from '../../../components/profile/CompanyProfile';
import AddInternshipForm from '../../../components/forms/AddInternshipForm';
import { CompanyStatuses } from '../../../constants/enums/CompanyStatuses';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AddInternshipScreen = () => {
  const [status, setStatus] = useState('');
  const query = useQuery();
  const {
    authStore: { user },
  } = useStore();

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      setStatus(JSON.parse(lsUser).status);
    }
  }, []);

  return (
    <StyledContainer>
      <CompanyProfile
        logoUrl={user ? user.logoUrl : ''}
        address={user ? user.address : ''}
        companyName={user ? user.name : ''}
        email={user ? user.email : ''}
      />
      <Spacer height={32} />
      {status === CompanyStatuses.Accepted && (
        <AddInternshipForm
          isEdit={query.get('id') !== null && query.get('id') !== undefined}
          internshipId={query.get('id')}
        />
      )}
    </StyledContainer>
  );
};

export default observer(AddInternshipScreen);
