import React, { useState } from 'react';
import styled from 'styled-components';
import { MdOutlineFilterAlt } from 'react-icons/md';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import {
  Select,
  SelectChangeEvent,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Collapse } from '@material-ui/core';

import { TextInput } from './TextInput';

const StyledDiv = styled.div`
  background-color: '#FAFAFA';
  height: auto;
  width: 100;
`;

const Row = styled.div`
  padding: 6px 12px;
  width: 100%;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFilterIcon = styled(MdOutlineFilterAlt)`
  font-size: 18px;
  margin-right: 6px;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin: 0px 16px;
`;

const IconTextGroup = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Props {
  nameValue: string;
  nameOnChange: (newValue: any) => void;
  majors: any[];
  majorsValue: any[];
  majorsOnChange: (newValue: any) => void;
  universityYear: number;
  universityYearOnChange: (newValue: any) => void;
  studyYear: number;
  studyYearOnChange: (newValue: any) => void;
}

export const ProfessorFilters = ({
  nameValue,
  nameOnChange,
  majors,
  majorsValue,
  majorsOnChange,
  universityYear,
  universityYearOnChange,
  studyYear,
  studyYearOnChange,
}: Props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen((open) => !open);
  const handleChangeSelect = (event: SelectChangeEvent<typeof majorsValue>) => {
    const {
      target: { value },
    } = event;
    majorsOnChange(typeof value === 'string' ? value.split(',') : value);
  };
  return (
    <StyledDiv>
      <Row onClick={handleOpen}>
        <IconTextGroup>
          <StyledFilterIcon />
          <p>Filtre studenti</p>
        </IconTextGroup>
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Row>
      <Row>
        <Collapse in={open} timeout="auto" style={{ width: '100%' }}>
          <Row>
            <InputWrapper>
              <TextInput label="Nume student" value={nameValue} name="studentName" onChange={nameOnChange} />
            </InputWrapper>
            <InputWrapper>
              <FormControl fullWidth>
                <InputLabel id="demo-multiple-chip-label">Programul de studii</InputLabel>
                <Select
                  multiple
                  fullWidth
                  value={majorsValue}
                  id="demo-multiple-chip"
                  labelId="demo-multiple-chip-label"
                  onChange={handleChangeSelect}
                  input={<OutlinedInput label="Programul de studii" id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {majors.map((maj) => (
                    <MenuItem key={maj.name} value={maj.name}>
                      {maj.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </InputWrapper>
            <InputWrapper>
              <FormControl fullWidth>
                <InputLabel id="label-study-year">Anul de studiu</InputLabel>
                <Select
                  labelId="label-study-year"
                  id="university-year-select"
                  value={studyYear}
                  label="Anul de studiu"
                  onChange={studyYearOnChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                </Select>
              </FormControl>
            </InputWrapper>
            <InputWrapper>
              <FormControl fullWidth>
                <InputLabel id="label-university-year">Anul universitar</InputLabel>
                <Select
                  labelId="label-university-year"
                  id="university-year-select"
                  value={universityYear}
                  label="Anul universitar"
                  onChange={universityYearOnChange}
                >
                  <MenuItem value={10}>2021-2022</MenuItem>
                  <MenuItem value={11}>2022-2023</MenuItem>
                  <MenuItem value={12}>2023-2024</MenuItem>
                </Select>
              </FormControl>
            </InputWrapper>
          </Row>
        </Collapse>
      </Row>
    </StyledDiv>
  );
};

/*

 

              */
