import styled from 'styled-components';
import { Colors } from '../../constants/style/Colors';

export const GraySubtitle = styled.h3`
  font-size: 24px;
  font-weight: 300;
  font-family: 'Poppins', sans-serif;
  color: ${Colors.LightGrey};
  font-size: 16px;
  margin: 0;
`;
