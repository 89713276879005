export interface Student {
  an: string;
  an_universitar: string;
  ani_studiu: string;
  cnp: string;
  den_facultate: string;
  den_grupa: string;
  den_specializare: string;
  forma_invatamant: string;
  grupa: string;
  id: string;
  id_facultate: string;
  inscris: string;
  liceu_an: string;
  liceu_denumire: string;
  liceu_specializare: string;
  matricol: string;
  nume: string;
  specializare: string;
  stare: string;
  superior_an_absolvire: string;
  superior_denumire: string;
  telefon: string;
  email: string;
}
export const STUDENT_INITIAL = {
  an: '',
  an_universitar: '',
  ani_studiu: '',
  cnp: '',
  den_facultate: '',
  den_grupa: '',
  den_specializare: '',
  forma_invatamant: '',
  grupa: '',
  id: '',
  id_facultate: '',
  inscris: '',
  liceu_an: '',
  liceu_denumire: '',
  liceu_specializare: '',
  matricol: '',
  nume: '',
  specializare: '',
  stare: '',
  superior_an_absolvire: '',
  superior_denumire: '',
  telefon: '',
  email: '',
};
