import React from 'react';
import styled from 'styled-components';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import EventBusyTwoToneIcon from '@mui/icons-material/EventBusyTwoTone';

import { Colors, Filters } from '../../constants/style/Colors';
import { ScreenSize } from '../../constants/responsive/MediaQueries';

import PrimaryButton from '../buttons/PrimaryButton';

const StyledContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e3e6ef;
  margin-right: 32px;
  padding: 16px 32px;

  ${ScreenSize.medium} {
    width: 100%;
    border-right: none;
    margin-right: 0px;
  }
`;

const InternshipHeader = styled.div`
  height: 256px;

  ${ScreenSize.medium} {
    height: auto;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  ${ScreenSize.medium} {
    flex-direction: column;
  }
`;

const InformationRow = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e6ef;

  ${ScreenSize.medium} {
    flex-direction: column;
  }
`;

const InternshipInformations = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;

  ${ScreenSize.medium} {
    width: 100%;
  }
`;

const InfoGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 64px;
  width: 100%;

  ${ScreenSize.medium} {
    padding: 0px;
  }
`;

const IconTextGroup = styled.div`
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding-bottom: 16px;
`;

const StyledLocationIcon = styled(LocationOnTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledPersonIcon = styled(PersonOutlineTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledWorkIcon = styled(WorkTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledCalendarIcon = styled(EventBusyTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;

const StyledTypography = styled.p`
  color: ${Colors.Primary};
`;

interface InternshipDetailsProps {
  title: string;
  location: string;
  seats: string;
  domain: string;
  deadline: string;
  children: React.ReactNode;
  handleOnClick: () => void;
}

export const IntershipDetails = ({
  title,
  location,
  seats,
  domain,
  deadline,
  children,
  handleOnClick,
}: InternshipDetailsProps) => (
  <StyledContainer>
    <InternshipHeader>
      <TitleContainer>
        <h1>{title}</h1>
        <PrimaryButton onClick={handleOnClick}>Aplică</PrimaryButton>
      </TitleContainer>
      <InformationRow>
        <InternshipInformations>
          <InfoGroup>
            <IconTextGroup>
              <StyledLocationIcon fontSize="medium" />
              <p>Locatie: </p>
            </IconTextGroup>
            <StyledTypography>{location}</StyledTypography>
          </InfoGroup>
          <InfoGroup>
            <IconTextGroup>
              <StyledPersonIcon fontSize="medium" />
              <p>Numar locuri disponibile: </p>
            </IconTextGroup>
            <StyledTypography>{seats}</StyledTypography>
          </InfoGroup>
        </InternshipInformations>
        <InternshipInformations>
          <InfoGroup>
            <IconTextGroup>
              <StyledWorkIcon fontSize="medium" />
              <p>Domeniu: </p>
            </IconTextGroup>
            <StyledTypography>{domain}</StyledTypography>
          </InfoGroup>
          <InfoGroup>
            <IconTextGroup>
              <StyledCalendarIcon fontSize="medium" />
              <p>Termen limita de aplicare: </p>{' '}
            </IconTextGroup>
            <StyledTypography>{deadline}</StyledTypography>
          </InfoGroup>
        </InternshipInformations>
      </InformationRow>
    </InternshipHeader>
    <Description>{children}</Description>
  </StyledContainer>
);
