import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import logo from '../../assets/images/logo_arad.png';
import { Routes } from '../../constants/routes';
import { Colors } from '../../constants/style/Colors';

import LoginForm from '../../components/forms/LoginForm';

const StyledContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const Column = styled.div`
  height: 100%;
  width: 100%;
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  height: auto;
  width: 156px;
`;

const Title = styled.h1`
  color: ${Colors.PrimaryDark};
  font-size: 24px;
`;

const StyledAnchor = styled.a`
  color: ${Colors.PrimaryDark};
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.5s ease-out;

  &:hover {
    color: ${Colors.Primary};
  }
`;

const LoginPage = () => {
  const history = useHistory();

  return (
    <StyledContainer>
      <Column>
        <Logo src={logo} alt="Logo Unviersitatea Aurel Vlaicu" />
        <Title>Conectează-te</Title>
        <LoginForm />
        <p
          style={{
            textAlign: 'center',
            paddingLeft: '36px',
            paddingRight: '36px',
          }}
        >
          Reprezentați un agent al mediului economic? Creați un
          <StyledAnchor
            onClick={(e) => {
              e.preventDefault();
              history.push(Routes.Register);
            }}
          >
            {' '}
            cont nou
          </StyledAnchor>
        </p>
      </Column>
    </StyledContainer>
  );
};

export default observer(LoginPage);
