import React, { useState } from 'react';
import { TableRow, TableCell, makeStyles } from '@material-ui/core';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ApplicantStatus } from 'components/statuses/ApplicantStauts';
import { TextButton } from 'components/buttons/TextButton';
import ApplicantCVModal from 'components/applicant/ApplicantCVModal';
import ApplicantReviewModal from 'components/applicant/ApplicantReviewModal';
import { useStore } from 'hooks/useStore';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    transition: 'background-color .4s',
  },

  cell: {
    border: 'none',
  },
});

const StyledContainer = styled.div`
  padding: 24px;
`;

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;

interface Props {
  cv: any;
  internshipId: string;
  internshipTitle: string;
  companyId: string;
  status: 'approved' | 'pending' | 'declined';
}

const ApplicantRow = ({ cv, internshipId, internshipTitle, status, companyId }: Props) => {
  const [openCV, setOpenCV] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const classes = useStyles();
  const {
    internshipStore: { acceptApplicant, declineApplicant },
  } = useStore();

  const handleAccept = () => {
    (async () => {
      const res = await acceptApplicant(internshipId, cv);
      console.log(res);
    })();
  };

  const handleDecline = () => {
    (async () => {
      const res = await declineApplicant(internshipId, cv);
      console.log(res);
    })();
  };

  const handleActions = () => {
    if (status === 'pending')
      return (
        <TableCell size="medium" className={classes.cell} align={'center'}>
          <SpacedRow>
            <TextButton color={'success'} icon={<CheckIcon />} onClick={handleAccept}>
              accepta
            </TextButton>
            <TextButton color={'error'} icon={<CloseIcon />} onClick={handleDecline}>
              respinge
            </TextButton>
          </SpacedRow>
        </TableCell>
      );
    if (status === 'approved')
      return (
        <TableCell className={classes.cell} align={'center'}>
          <StyledContainer>
            <TextButton color={'primary'} onClick={() => setOpenReview((prev) => !prev)}>
              Adeverința de practică
            </TextButton>
          </StyledContainer>
        </TableCell>
      );

    return '';
  };

  return (
    <>
      <TableRow className={`${clsx(classes.root)}`}>
        <TableCell className={classes.cell} align={'left'}>
          {cv.name}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {cv.faculty.major}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {cv.faculty.yearsOfStudy}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {internshipTitle}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          <TextButton
            color={'primary'}
            onClick={() => {
              setOpenCV((prev) => !prev);
            }}
          >
            vizualizeaza CV
          </TextButton>{' '}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          <ApplicantStatus status={status} />
        </TableCell>
        {handleActions()}
      </TableRow>
      <TableRow style={{ display: 'none' }}>
        <TableCell style={{ display: 'none' }}>
          <ApplicantCVModal
            open={openCV}
            handleClose={() => {
              setOpenCV((prev) => !prev);
            }}
            cv={cv}
          />
          {openReview && (
            <ApplicantReviewModal
              companyId={companyId}
              internshipId={internshipId}
              studentId={cv.studentId}
              cvId={cv._id}
              courseId={cv.courseId}
              open={openReview}
              handleClose={() => {
                setOpenReview((prev) => !prev);
              }}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default observer(ApplicantRow);
