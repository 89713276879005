import React from 'react';
import styled from 'styled-components';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { IntershipStatusColors } from '../../constants/style/Colors';

const StyledContainer = styled.div`
  width: fit-content;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ScreenSize.medium} {
    flex-direction: row;
  }
`;

const Badge = styled.span<{ color: 'success' | 'warning' }>`
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 10px;
  text-align: center;
  color: ${(props) =>
    props.color === 'success' ? IntershipStatusColors.SuccessDark : IntershipStatusColors.WarningDark};
  background-color: ${(props) =>
    props.color === 'success' ? IntershipStatusColors.Success : IntershipStatusColors.Warning};
`;

const Typography = styled.p`
  margin: 0px 6px 0px 0px;
  font-size: 12px;
`;

interface InternshipStatusProps {
  seats: number;
  applicants: number;
}

export const InternshipStatus = ({ seats, applicants }: InternshipStatusProps) => {
  const determineColor = (seatsNumber: number, applicantsNumber: number) =>
    seatsNumber > applicantsNumber ? 'success' : 'warning';
  return (
    <StyledContainer>
      <Typography>Aplicanti / Locuri</Typography>
      <Badge color={determineColor(seats, applicants)}>
        {applicants}
        {' / '}
        {seats}
      </Badge>
    </StyledContainer>
  );
};
