import React from 'react';
import styled from 'styled-components';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import EventBusyTwoToneIcon from '@mui/icons-material/EventBusyTwoTone';
import { experimentalStyled as MUIstyled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Filters } from '../../constants/style/Colors';

import PrimaryButton from '../buttons/PrimaryButton';
import { InternshipStatus } from './InternshipStatus';

const Row = styled.div`
  height: auto;
  padding: 16px 0px 16px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledContainer = styled.div`
  height: 256px;
  width: 720px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  padding: 0 40px;
  border-bottom: 1px dashed #e3e6ef;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ScreenSize.mediumSmall} {
    height: auto;
    width: 100%;
    padding-bottom: 16px;
  }
`;

const StyledLogo = styled.img`
  max-width: 64px;
  height: auto;
  display: block;
`;

const CompanyInfo = styled.div`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const CardFooter = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
`;

const Headline = styled.h1<{ size?: string; color?: string }>`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: 600;
  margin: 0px 16px 0px 16px;
  transition: color 0.2s ease, background-color 0.2s ease;
  &:hover {
    color: #0096e7;
    cursor: pointer;
  }
`;

const SubHeadline = styled.h2<{
  size?: string;
  color?: string;
  weight?: string;
}>`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: ${(props) => (props.weight ? props.weight : '200')};
  color: #474747;
  margin: 0px 16px;
`;

const Description = styled.p<{
  size?: string;
  color?: string;
  weight?: string;
}>`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: ${(props) => (props.weight ? props.weight : '200')};
  color: #474747;
`;

const DateStart = styled.p<{ descriptive?: boolean }>`
  margin: ${(props) => (props.descriptive ? '0px 0px 6px 0px' : '0px 0px 0px 6px')};
  font-size: 12px;
  color: #1764c0;
`;

const DateEnd = styled.p<{ descriptive?: boolean }>`
  margin: ${(props) => (props.descriptive ? '0px 0px 6px 0px' : '0px 0px 0px 6px')};
  font-size: 12px;
  color: #ff474a;
`;

const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  align-items: flex-start;

  ${ScreenSize.small} {
    width: 100%;
  }
`;

const DateIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0;

  ${ScreenSize.small} {
    width: 100%;
  }
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ScreenSize.small} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const StyledPostedDate = MUIstyled(TodayTwoToneIcon)`
  font-size: 24px;
  filter: invert(28%) sepia(75%) saturate(1108%) hue-rotate(186deg)
    brightness(103%) contrast(103%);
`;

const StyledEndDate = MUIstyled(EventBusyTwoToneIcon)`
font-size: 24px;
  filter: ${Filters.Danger};
`;

const BadgeWrapper1 = styled.div`
  margin-left: auto;
  ${ScreenSize.small} {
    display: none;
  }
`;
const BadgeWrapper2 = styled.div`
  display: none;
  ${ScreenSize.small} {
    display: block;
    width: 100%;
  }
`;

interface InternshipCardProps {
  title: string;
  company: string;
  description: string;
  logo: string;
  seats: number;
  applicants: number;
  startDate: string;
  endDate: string;
  to: string;
}

export const InternshipCard = observer(
  ({ title, company, description, logo, seats, applicants, startDate, endDate, to }: InternshipCardProps) => {
    const history = useHistory();
    return (
      <StyledContainer>
        <Row>
          <StyledLogo src={logo} />
          <CompanyInfo>
            <Headline size="20px">{title}</Headline>
            <SubHeadline size="16px">{company}</SubHeadline>
          </CompanyInfo>
          <BadgeWrapper1>
            <InternshipStatus seats={seats} applicants={applicants} />
          </BadgeWrapper1>
        </Row>
        <BadgeWrapper2>
          <InternshipStatus seats={seats} applicants={applicants} />
        </BadgeWrapper2>
        <Description size="12px" weight="400">
          {description}
        </Description>
        <CardFooter>
          <Col>
            <DateContainer>
              <DateStart descriptive>Postat la:</DateStart>
              <DateIconContainer>
                <StyledPostedDate fontSize="large" />
                <DateStart>{startDate}</DateStart>
              </DateIconContainer>
            </DateContainer>
            <DateContainer>
              <DateEnd descriptive>Expiră la:</DateEnd>
              <DateIconContainer>
                <StyledEndDate fontSize="large" />
                <DateEnd>{endDate}</DateEnd>
              </DateIconContainer>
            </DateContainer>
          </Col>
          <PrimaryButton
            onClick={() => {
              history.push(to);
            }}
            variant="outlined"
          >
            Aplică acum
          </PrimaryButton>
        </CardFooter>
      </StyledContainer>
    );
  }
);
