import { makeAutoObservable, runInAction } from 'mobx';
import { ApplicantFileApi } from '../api/ApplicantFileApi';

export class ApplicantFileStore {
  api: ApplicantFileApi;
  files: any[] = [];
  loading: boolean = false;

  constructor(api: ApplicantFileApi) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.api = api;
  }

  async listByCvId(id: string) {
    this.loading = true;
    const res = await this.api.listByCvId(id);
    runInAction(() => {
      this.loading = false;
      this.files = res.data;
    });
    return res;
  }
}
