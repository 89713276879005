import { Colors } from 'constants/style/Colors';
import React from 'react';
import styled from 'styled-components';

import Logo from '../../assets/images/logo_arad.png';

import { RegisterForm } from '../../components/forms/RegisterForm';

const StyledContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  /* background-color: ${Colors.PrimaryBackground}; */
`;

const FormContainer = styled.div`
  max-width: 900px;
  width: 100%;
  margin: auto;
  background-color: ${Colors.White};
  border-radius: 16px;
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 64px;
`;

const LogoImage = styled.img`
  height: auto;
  width: 156px;
  align-self: center;
`;

const RegisterPage = () => {
  return (
    <StyledContainer>
      <FormContainer>
        <Column>
          <LogoImage src={Logo} alt={'Logo not found!'} />
          <RegisterForm />
        </Column>
      </FormContainer>
    </StyledContainer>
  );
};

export default RegisterPage;
