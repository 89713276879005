import { CvHelpDto } from 'models/CVHelp';
import { ApiConfig } from './ApiConfig';

const DEFAULT_ENDPOINT = '/helprequest';

export class CVHelpApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  add(data: CvHelpDto) {
    return this.apiConfig.sendRequest('POST', DEFAULT_ENDPOINT, data);
  }
  listByStudentId(studentId: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/student/${studentId}`, null);
  }

  listByCourseId(courseId: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/course/${courseId}`, null);
  }
}
