import React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '56px',
    padding: 0,

    '& .MuiInputBase-root': {
      height: 56,
      boxSizing: 'border-box',
      border: 'none',
      outline: 'none',
    },
  },
});

interface Props {
  label: string;
  placeholder?: string;
  value: Date | null;
  onChange: (newValue: Date | null) => void;
}

export const AppDatePicker = ({ value, onChange, label }: Props) => {
  const classes = useStyles();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={value}
        onChange={onChange}
        renderInput={(params) => <TextField className={classes.root} {...params} />}
      />
    </LocalizationProvider>
  );
};
