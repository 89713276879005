import React, { useEffect } from 'react';
import styled from 'styled-components';
import { AiOutlinePlus } from 'react-icons/ai';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';

import { Colors } from 'constants/style/Colors';

import { useStore } from '../../../hooks/useStore';
import { CompanyProfile } from '../../../components/profile/CompanyProfile';
import { Spacer } from 'components/spacer/Spacer';
import { InternshipDashboardCard } from 'components/internship/InternshipDashboardCard';
import { Routes } from 'constants/routes';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1800px;
  margin: auto;
`;

const Row = styled.div`
  width: 100%;

  margin: auto;
`;

const DashboardInternshipsPage = () => {
  const {
    authStore: { user },
    internshipStore: { listByCompany, internships, loading },
  } = useStore();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const lsUser = localStorage.getItem('user');
      if (lsUser) {
        const user = JSON.parse(lsUser);
        await listByCompany(user._id);
      }
    })();
  }, []);

  return (
    <StyledContainer>
      <CompanyProfile
        action={{
          title: 'Adauga ',
          icon: <AiOutlinePlus color={Colors.White} />,
          handler: () => {
            history.push(Routes.DashboardAddInternship);
          },
        }}
        logoUrl={user ? user.logoUrl : ''}
        address={user ? user.address : ''}
        companyName={user ? user.name : ''}
        email={user ? user.email : ''}
      />
      <Spacer height={32} />
      <Row>
        {loading
          ? 'Loading...'
          : internships.map((i) => (
              <InternshipDashboardCard
                onClick={() => {
                  history.push(`${Routes.DashboardInternships}/details/${i._id}`);
                }}
                key={i._id}
                internship={i}
              />
            ))}
      </Row>
    </StyledContainer>
  );
};

export default observer(DashboardInternshipsPage);
