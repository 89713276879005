import React, { useState } from 'react';
import { TableRow, TableCell, Collapse, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import LanguageIcon from '@mui/icons-material/Language';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { FaPhone } from 'react-icons/fa';
import { FcCalendar } from 'react-icons/fc';
import { MdLocationOn } from 'react-icons/md';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import clsx from 'clsx';

import { Colors, StatusColors } from 'constants/style/Colors';

import { CompanyStatus } from 'components/statuses/CompanyStatus';
import { Spacer } from 'components/spacer/Spacer';
import { TextButton } from 'components/buttons/TextButton';
import { useStore } from '../../hooks/useStore';
import { CompanyStatuses } from '../../constants/enums/CompanyStatuses';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    transition: 'background-color .4s',
  },

  cell: {
    border: 'none',
  },
});

const StyledContainer = styled.div`
  padding: 24px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${Colors.Grey};
  font-weight: 400;
`;

const Grey = styled.span`
  color: ${Colors.Grey};
  padding: 0;
  margin: 0;
  display: inline-block;
`;

interface Props {
  name: string;
  email: string;
  status: 'approved' | 'pending' | 'declined';
  phone: string;
  address: string;
  fiscalCode: string;
  registrationCode: string;
  established: string;
  websiteUrl: string;
  description: string;
  contactPersons: string[];
  id: string;
}

const CompanyRow = ({
  name,
  email,
  status,
  phone,
  address,
  fiscalCode,
  registrationCode,
  established,
  websiteUrl,
  description,
  contactPersons,
  id,
}: Props) => {
  const [open, setOpen] = useState(false);
  const {
    companyStore: { update },
  } = useStore();
  const classes = useStyles();

  const handleUpdate = (status: CompanyStatuses | any) => {
    update(id, { status });
  };

  return (
    <>
      <TableRow
        className={`${clsx(classes.root)}`}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <TableCell className={classes.cell} align={'left'}>
          {name}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {websiteUrl}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {email}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          <CompanyStatus status={status} />
        </TableCell>
        <TableCell className={classes.cell}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StyledContainer>
              <SpacedRow>
                <Column>
                  <Title>Informatii contact</Title>
                  <Row>
                    <FaPhone size={20} color={StatusColors.Danger} /> <Spacer width={8} /> {phone}
                  </Row>
                  <Row>
                    <MdLocationOn size={20} color={StatusColors.Danger} /> <Spacer width={8} /> {address}
                  </Row>
                </Column>

                <Column>
                  <Title>Informatii generale</Title>
                  <Row>
                    <FcCalendar size={20} /> <Spacer width={8} /> {established}
                  </Row>
                  <Row>
                    <Grey>
                      <LanguageIcon color={'inherit'} /> <Spacer width={8} />
                    </Grey>
                    <Spacer width={8} />
                    {websiteUrl}
                  </Row>
                </Column>

                <Column>
                  <Title>Date fiscale</Title>
                  <Row>
                    <Grey>CIF</Grey>
                    <Spacer width={8} /> {fiscalCode}
                  </Row>
                  <Row>
                    <Grey>Nr. Registru Comert. </Grey> <Spacer width={8} /> {registrationCode}
                  </Row>
                </Column>

                <Column>
                  <Title>Actiuni</Title>
                  <TextButton
                    color={'success'}
                    icon={<CheckIcon />}
                    onClick={() => {
                      handleUpdate(CompanyStatuses.Accepted);
                    }}
                  >
                    accepta
                  </TextButton>
                  <TextButton
                    color={'error'}
                    icon={<CloseIcon />}
                    onClick={() => {
                      handleUpdate(CompanyStatuses.Declined);
                    }}
                  >
                    respinge
                  </TextButton>
                </Column>
              </SpacedRow>
              <SpacedRow>
                <Column>
                  <Title>Descriere</Title>
                  <p>{description}</p>
                </Column>
              </SpacedRow>
              <SpacedRow>
                <Column>
                  <Title>Persoane de contact</Title>
                  {contactPersons.map((p) => (
                    <p>{p}</p>
                  ))}
                </Column>
              </SpacedRow>
            </StyledContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default observer(CompanyRow);
