export const DOMAINS = [
  { id: 1, domain: 'Arte Vizuale', faculty: 3 },
  { id: 2, domain: 'Educaţie fizică şi sport', faculty: 4 },
  { id: 3, domain: 'Inginerie şi Management', faculty: 7 },
  { id: 4, domain: 'Inginerie Industrială', faculty: 7 },
  { id: 5, domain: 'Administrarea Afacerilor', faculty: 2 },
  { id: 7, domain: 'Contabilitate', faculty: 2 },
  { id: 8, domain: 'Ingineria Produselor Alimentare', faculty: 1 },
  { id: 9, domain: 'Inginerie şi Management în Agricultură şi Dezvoltare Rurală', faculty: 1 },
  { id: 10, domain: 'Ingineria Mediului', faculty: 1 },
  { id: 12, domain: 'Finanţe', faculty: 2 },
  { id: 13, domain: 'Marketing', faculty: 2 },
  { id: 14, domain: 'Management', faculty: 2 },
  { id: 15, domain: 'Asistenţă Socială', faculty: 5 },
  { id: 16, domain: 'Stiințe ale Educației', faculty: 5 },
  { id: 17, domain: 'Psihologie', faculty: 5 },
  { id: 18, domain: 'Informatică', faculty: 8 },
  { id: 19, domain: 'Ingineria autovehiculelor', faculty: 7 },
  { id: 21, domain: 'Matematică', faculty: 8 },
  { id: 22, domain: 'Ingineria Sistemelor', faculty: 7 },
  { id: 23, domain: 'Teologie', faculty: 6 },
  { id: 25, domain: 'Științe ale Comunicării', faculty: 9 },
  { id: 26, domain: 'Ştiinţe administrative', faculty: 9 },
  { id: 27, domain: 'Limbă şi literatură', faculty: 9 },
  { id: 30, domain: 'Teologie', faculty: 9 },
  { id: 31, domain: 'Asistenţă Socială', faculty: 5 },
  { id: 32, domain: 'Știința Sportului și Educației Fizice', faculty: 4 },
  { id: 33, domain: 'Doctorat Filologie', faculty: 9 },
  { id: 34, domain: 'Filologie', faculty: 9 },
  { id: 35, domain: 'Muzică', faculty: 9 },
  { id: 36, domain: 'Științe juridice', faculty: 9 },
  { id: 38, domain: 'Drept', faculty: 9 },
  { id: 39, domain: 'Sociologie', faculty: 5 },
  { id: 40, domain: 'Kinetoterapie', faculty: 4 },
];
