import React, { useState, useEffect } from 'react';
import { TableRow, TableCell, Collapse, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import { FcDownload } from 'react-icons/fc';
import styled from 'styled-components';
import clsx from 'clsx';

import { Colors } from 'constants/style/Colors';

import { useStore } from 'hooks/useStore';

const useStyles = makeStyles({
  root: {
    cursor: 'pointer',
    transition: 'background-color .4s',
  },

  cell: {
    border: 'none',
  },
});

const StyledContainer = styled.div`
  padding: 24px;
`;

const StyledDownloadIcon = styled(FcDownload)`
  font-size: 18px;
  margin-right: 6px;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  font-size: 20px;
  color: ${Colors.Grey};
  font-weight: 400;
`;

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  internshipId: string;
  cvId: string;
  name: string;
  studyProgram: string;
  project: any;
  notebook: any;
  report: any;
  yearOfStudy: number;
}

const StudentRow = ({
  internshipId,
  cvId,
  name,
  studyProgram,
  project,
  yearOfStudy,
  notebook,
  report,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [certificate, setCertificate] = useState<any>();
  const classes = useStyles();

  const {
    applicantFileStore: { listByCvId },
  } = useStore();

  useEffect(() => {
    (async () => {
      const certificateResponse = await listByCvId(cvId);
      setCertificate(certificateResponse.data[certificateResponse.data.length - 1]);
    })();
  }, []);
  return (
    <>
      <TableRow
        className={`${clsx(classes.root)}`}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
      >
        <TableCell className={classes.cell} align={'left'}>
          {name}
        </TableCell>

        <TableCell className={classes.cell} align={'left'}>
          {studyProgram}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {yearOfStudy}
        </TableCell>
        <TableCell className={classes.cell} align={'left'}>
          {'2021 - 2022'}
        </TableCell>
        <TableCell className={classes.cell}>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <StyledContainer>
              <SpacedRow>
                <Column>
                  <Title>Raport de practica</Title>
                  <IconText>
                    <StyledDownloadIcon />
                    {report !== undefined ? (
                      <a href={`${process.env.REACT_APP_API_URL}/file/download/${report.fileId}`}>
                        {report.filename}
                      </a>
                    ) : (
                      '-'
                    )}
                  </IconText>
                </Column>
                <Column>
                  <Title>Proiect de practica</Title>
                  <IconText>
                    <StyledDownloadIcon />
                    {project !== undefined ? (
                      <a href={`${process.env.REACT_APP_API_URL}/file/download/${project.fileId}`}>
                        {project.filename}
                      </a>
                    ) : (
                      '-'
                    )}
                  </IconText>
                </Column>
                <Column>
                  <Title>Caiet de practica</Title>
                  <IconText>
                    <StyledDownloadIcon />
                    {notebook !== undefined ? (
                      <a href={`${process.env.REACT_APP_API_URL}/file/download/${notebook.fileId}`}>
                        {notebook.filename}
                      </a>
                    ) : (
                      '-'
                    )}
                  </IconText>
                </Column>
                <Column>
                  <Title>Adeverinta de practica</Title>
                  <IconText>
                    <StyledDownloadIcon />
                    {certificate !== undefined ? (
                      <a href={`${process.env.REACT_APP_API_URL}/file/download/${notebook.fileId}`}>
                        {certificate.filename}
                      </a>
                    ) : (
                      '-'
                    )}
                  </IconText>
                </Column>
              </SpacedRow>
            </StyledContainer>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default observer(StudentRow);
