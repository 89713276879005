import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router';
import { observer } from 'mobx-react-lite';

import { CompanyInfo } from '../../components/internship/CompanyInfo';
import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Routes } from 'constants/routes';

import { AppBar } from '../../components/navigation/AppBar';
import { IntershipDetails } from '../../components/internship/IntershipDetails';
import { Footer } from '../../components/footer/Footer';
import { useStore } from 'hooks/useStore';
import { toast } from 'react-toastify';

const CenterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Spacer = styled.div`
  width: 20%;

  ${ScreenSize.medium} {
    display: none;
  }
`;

const MainContent = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 32px 0px;

  ${ScreenSize.medium} {
    flex-direction: column;
  }
`;

const StyledDiv = styled.div`
  min-height: 42vh;
  ${ScreenSize.medium} {
    min-height: auto;
  }
`;

interface ParamsType {
  id: string;
}

const InternshipScreen = () => {
  const { id } = useParams<ParamsType>();
  const {
    cvStore,
    internshipStore: { getOne, current, addApplicant },
  } = useStore();

  const history = useHistory();
  const applyToInternship = async () => {
    if (cvStore.current === undefined) {
      toast.error('Nu aveti CV-ul salvat', { onClose: () => history.push(Routes.Profile) });
    } else if (
      current.applicants.map((a) => a._id).includes(cvStore.current._id) ||
      current.declinedApplicants.map((a) => a._id).includes(cvStore.current) ||
      current.acceptedApplicants.map((a) => a._id).includes(cvStore.current)
    ) {
      toast.error('Ați aplicat deja pentru acest program');
    } else {
      const res = await addApplicant(id, cvStore.current._id);
      if (res.status === 200)
        toast.success('Ati aplicat cu succes', { onClose: () => history.push(Routes.Home) });
      return res;
    }
  };

  useEffect(() => {
    (async () => {
      getOne(id);
      const lsUser = localStorage.getItem('user');
      if (lsUser) {
        const user = JSON.parse(lsUser);
        cvStore.getByStudentId(user.student.id);
      }
    })();
  }, []);

  return (
    <CenterContainer>
      <AppBar />
      <MainContent>
        <IntershipDetails
          title={current.title}
          location={current.location}
          seats={current.maxPositions}
          domain={current.domain}
          deadline={new Date(current.endDate).toLocaleDateString('ro-RO')}
          handleOnClick={applyToInternship}
        >
          <StyledDiv dangerouslySetInnerHTML={{ __html: current.description }} />
        </IntershipDetails>
        <Spacer />
        <CompanyInfo
          name={current.companyId.name}
          logoSrc={current.companyId.logoUrl}
          address={current.companyId.address}
          website={current.companyId.websiteUrl}
          contacts={current.companyId.contacts}
        />
      </MainContent>
      <Footer />
    </CenterContainer>
  );
};
export default observer(InternshipScreen);
