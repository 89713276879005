export const DrawerColors = {
  Background: '#1e1e2d',
  BackgroundActive: '#1a1a27',
  FontColor: '#9899ac',
  FontColorDark: '#41425f',
  IconFontColor: '#494b74',
};

export const DashboardAppBarColors = {
  ActiveLinkBackground: '#f7f9fb',
};

export const StatusColors = {
  Success: '#50cd89',
  SuccessBg: '#e7fff3',
  Warning: '#ffc700',
  WarningBg: '#fff8dd',
  Danger: '#f1416c',
  DangerBg: '#fff5f8',
};

export const Colors = {
  White: '#fff',
  Black: '#181c32',
  PrimaryBackground: '#f5f8fa',
  Primary: '#009ef7',
  PrimaryDark: '#02487d',
  Secondary: '#134266',
  Grey: '#a1a5b7',
  LightGrey: '#b5b5c3',
  Danger: '#f1416c',
  Border: '#DFE1E6',
};

export const Filters = {
  Icon: 'invert(71%) sepia(23%) saturate(181%) hue-rotate(192deg) brightness(91%) contrast(83%);',
  Danger: 'invert(36%) sepia(29%) saturate(5941%) hue-rotate(324deg) brightness(101%) contrast(89%)',
  Blue: 'invert(28%) sepia(75%) saturate(1108%) hue-rotate(186deg)brightness(103%) contrast(103%)',
  Success: 'invert(92%) sepia(13%) saturate(1185%) hue-rotate(53deg) brightness(98%) contrast(93%)',
};

export const IntershipStatusColors = {
  Success: '#a6f39c',
  SuccessDark: '#007a21',
  Warning: '#ffec80',
  WarningDark: '#968000',
};
