import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { verify } from 'jsonwebtoken';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import Logo from 'assets/images/logo_arad.png';
import { Routes } from '../../constants/routes';
import { Colors } from 'constants/style/Colors';
import { Groups } from 'constants/enums/Groups';

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 250px;
  height: auto;
`;

export const AutoLoginPage = () => {
  const params = useParams() as any;
  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (params.token) {
        console.log('PARSM TOKEN IS', params.token);
        localStorage.setItem('access_token', params.token);

        try {
          const decoded = (await verify(params.token, process.env.REACT_APP_JWT_SECRET_KEY || '')) as any;
          decoded.user_groups = Object.keys(decoded.user_groups).map((g) => parseInt(g));
          localStorage.setItem('user', JSON.stringify(decoded));

          console.log(decoded);

          if (decoded.user_groups.includes(Groups.Student)) history.push(Routes.Home);
          else history.push(Routes.Dashboard);
        } catch (e) {
          console.log('Eerror is ', e);
          history.push(Routes.Login);
        }
      } else {
        history.push(Routes.Login);
      }
    })();
  }, [params]);

  return (
    <StyledContainer>
      <Column>
        <StyledImage src={Logo} />
        <ClipLoader color={Colors.Primary} />
      </Column>
    </StyledContainer>
  );
};
