import { ApiConfig } from './ApiConfig';

export class AuthApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  login(email: string, password: string) {
    return this.apiConfig.sendRequest('POST', '/company-auth/login', {
      username: email,
      password,
    });
  }
}
