import { UserRoles } from '../constants/enums/UserRoles';

export enum CompanyStatus {
  Approved = 'approved',
  Pending = 'pending',
  Declined = 'declined',
}

export interface CompanyDocument {
  _id: string;
  name: string;
  address: string;
  fiscalCode: string;
  registrationCode: string;
  established: string;
  email: string;
  phone: string;
  description: string;
  websiteUrl: string;
  contacts: string[];
  password: string;
  status: CompanyStatus;
  role: UserRoles;
  logoUrl: string;
  faculties: string[];
}

export interface CompanyModel {
  name: string;
  address: string;
  fiscalCode: string;
  registrationCode: string;
  established: string;
  email: string;
  phone: string;
  description: string;
  websiteUrl: string;
  contacts: string[];
  password: string;
  status: CompanyStatus;
  role: UserRoles;
  logoUrl: string;
  faculties: string[];
}
