import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CategoryCheckbox } from './CategoryCheckbox';

import { DOMAINS } from '../../constants/domains/Domains';
import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Groups } from 'constants/enums/Groups';

const StyledFilter = styled.div`
  width: 15%;
  padding-right: 16px;
  margin: 32px 0px;
  border-right: 1px solid #e4e6ef;

  ${ScreenSize.mediumSmall} {
    display: none;
  }
`;

const StyledTypography = styled.p`
  color: #7e8299;
  font-size: 16px;
`;

export const CategoryFilter = () => {
  const [userFaculty, setUserFaculty] = useState({});

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      if (user.user_groups.includes(Groups.Student)) {
        const obj: any = {};
        obj[`${user.student.id_facultate}`] = user.student.den_facultate;
        setUserFaculty(obj);
      } else setUserFaculty(user.facultatea);
    }
  }, []);

  return (
    <StyledFilter>
      <StyledTypography>Filtreaza dupa domenii</StyledTypography>
      {DOMAINS.map((domain: any) => {
        if (domain.faculty in userFaculty)
          return <CategoryCheckbox key={domain.id}>{domain.domain}</CategoryCheckbox>;
        return null;
      })}
    </StyledFilter>
  );
};
