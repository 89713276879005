import React, { useState, useEffect } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { MdLogout, MdAddBox, MdFormatListBulleted, MdPermIdentity } from 'react-icons/md';
import { BsFillBriefcaseFill } from 'react-icons/bs';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Groups } from 'constants/enums/Groups';
import { Routes } from '../../constants/routes';
import { Colors, DrawerColors } from '../../constants/style/Colors';

import DrawerItem from './DrawerItem';
import { Spacer } from '../spacer/Spacer';
import { useStore } from '../../hooks/useStore';
import { verify } from '../../services/jwt';

const StyledContainer = styled.div<{ open: boolean }>`
  width: ${(props) => (props.open ? '360px' : '60px')};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${DrawerColors.Background};
  transition: width 0.4s;
  padding-bottom: 32px;
`;

const DrawerHeader = styled.div<{ open: boolean }>`
  width: 100%;
  height: 65px;
  padding-left: ${(props) => (props.open ? '32px' : '8px')};
  padding-right: ${(props) => (props.open ? '32px' : '8px')};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.open ? 'flex-start' : 'center')};
  background-color: ${DrawerColors.BackgroundActive};
`;

const DrawerContent = styled.div``;

const DrawerTitle = styled.h1`
  text-transform: uppercase;
  color: ${Colors.White};
  font-size: 21px;
`;

const SectionTitle = styled.h3<{ open: boolean }>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  font-size: 16px;
  font-weight: 500;
  color: ${DrawerColors.FontColorDark};
  margin-left: 32px;
  margin-top: 32px;
`;

const IconContainer = styled.div`
  color: ${DrawerColors.IconFontColor};
  transition: color 0.4s;

  &:hover {
    color: ${Colors.Primary};
  }
`;
const ProfileIcon = styled(MdPermIdentity)`
  margin-right: 8px;
`;
const ProfileContainer = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  padding: 32px 0px 0px 32px;
  max-width: 280px;
  color: ${Colors.Primary};
  align-items: center;
`;

const LogoutBtn = styled.div<{ open: boolean }>`
  width: 100%;
  height: 40px;
  cursor: pointer;
  padding-left: ${(props) => (props.open ? '32px' : '0')};
  display: flex;
  align-items: center;
  transition: all 0.4s;
  color: ${DrawerColors.IconFontColor};
  ${(props) => props.open && '32px'};
  justify-content: ${(props) => (props.open ? 'flex-start' : 'center')};

  &:hover {
    background-color: ${DrawerColors.BackgroundActive};
    color: ${Colors.Danger};
  }
`;

const DashboardDrawer = () => {
  const [user, setUser] = useState<any>({ user_groups: [] });
  const [open, setOpen] = useState(true);
  const [professorName, setProfessorName] = useState<string[]>([]);
  const [deanName, setDeanName] = useState<string[]>([]);
  const { authStore } = useStore();
  const history = useHistory();

  useEffect(() => {
    const lsToken = localStorage.getItem('access_token');
    const lsUser = localStorage.getItem('user');
    if (lsToken && lsUser) {
      const payload = verify(lsToken) as any;

      if (!Array.isArray(payload.user_groups)) {
        payload.user_groups = Object.keys(payload.user_groups).map((g) => parseInt(g));
      }

      if (payload.user_groups.includes(Groups.Dean)) {
        const dName: any[] = Object.values(payload.nume).map((p) => p);
        setDeanName(dName);
      }

      if (payload.user_groups.includes(Groups.Professor)) {
        const pName: any[] = Object.values(payload.cadru).map((p) => p);
        setProfessorName(pName);
      }

      if (payload.user_groups.includes(Groups.Student)) history.push(Routes.Login);
      setUser(payload);

      const { innerWidth: width } = window;
      if (width <= 900) setOpen(false);
    }
  }, []);

  return (
    <StyledContainer open={open}>
      <DrawerContent>
        <DrawerHeader open={open}>
          {open && <DrawerTitle>Smart internship</DrawerTitle>}
          {open && <Spacer style={{ flex: 1 }} />}

          <IconContainer
            onClick={() => {
              setOpen((prev) => !prev);
            }}
          >
            {open ? (
              <IoIosArrowBack
                size={20}
                style={{ transition: 'all 0.4s', cursor: 'pointer' }}
                color={'inherit'}
              />
            ) : (
              <IoIosArrowForward
                size={20}
                style={{ transition: 'all 0.4s', cursor: 'pointer' }}
                color={'inherit'}
              />
            )}
          </IconContainer>
        </DrawerHeader>

        {user.user_groups.includes(Groups.Dean) && (
          <ProfileContainer open={open}>
            <ProfileIcon />
            <p>{deanName}</p>
          </ProfileContainer>
        )}
        {user.user_groups.includes(Groups.Professor) && (
          <ProfileContainer open={open}>
            <ProfileIcon />
            <p>{professorName}</p>
          </ProfileContainer>
        )}

        <SectionTitle open={open}>General</SectionTitle>
        {user.user_groups.includes(Groups.Dean) && (
          <DrawerItem
            open={open}
            to={Routes.DashboardCompanies}
            text={'Agenți ai mediului economic'}
            icon={<BsFillBriefcaseFill color={'inherit'} size={20} />}
          />
        )}

        {user.user_groups.includes(Groups.Professor) && (
          <DrawerItem
            open={open}
            to={Routes.DashboardStudentsList}
            text={'Lista studenti'}
            icon={<MdFormatListBulleted color={'inherit'} size={20} />}
          />
        )}

        {user.user_groups.includes(Groups.Professor) && (
          <DrawerItem
            open={open}
            to={Routes.StudentsRequestHelp}
            text={'Raport studenti cerere ajutor'}
            icon={<MdFormatListBulleted color={'inherit'} size={20} />}
          />
        )}

        {user.user_groups.includes(Groups.Company) && (
          <DrawerItem
            open={open}
            to={Routes.DashboardInternships}
            text={'Programe practica'}
            icon={<BsFillBriefcaseFill color={'inherit'} size={20} />}
          />
        )}

        {user.user_groups.includes(Groups.Company) && (
          <DrawerItem
            open={open}
            to={Routes.DashboardAddInternship}
            text={'Creeaza program'}
            icon={<MdAddBox color={'inherit'} size={20} />}
          />
        )}
      </DrawerContent>
      <LogoutBtn
        open={open}
        onClick={() => {
          authStore.logout();
          history.push(Routes.Login);
        }}
      >
        <MdLogout color={'inherit'} size={20} />
        <Spacer width={16} />
        {open && 'Logout'}
      </LogoutBtn>
    </StyledContainer>
  );
};

export default observer(DashboardDrawer);
