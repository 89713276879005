import React from 'react';
import styled from 'styled-components';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';

import { ScreenSize } from '../../../constants/responsive/MediaQueries';
import { Colors, Filters } from '../../../constants/style/Colors';

import PrimaryButton from '../../buttons/PrimaryButton';

const Row = styled.div`
  height: auto;
  padding: 16px 0px 16px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const StyledContainer = styled.div`
  height: 256px;
  width: 720px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
  border-bottom: 1px dashed #e3e6ef;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${ScreenSize.mediumSmall} {
    height: auto;
    width: auto;
    padding-bottom: 16px;
  }
`;

const StyledLogo = styled.img`
  max-width: 64px;
  height: auto;
  display: block;
`;

const CompanyInfo = styled.div`
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const CardFooter = styled.div`
  height: 30%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;
`;

const Headline = styled.h1<{ size?: string; color?: string }>`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: 600;
  margin: 0px 16px 0px 16px;
  transition: color 0.2s ease, background-color 0.2s ease;
  &:hover {
    color: #0096e7;
    cursor: pointer;
  }
`;

const SubHeadline = styled.h2<{
  size?: string;
  color?: string;
  weight?: string;
}>`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: ${(props) => (props.weight ? props.weight : '200')};
  color: #474747;
  margin: 0px 16px;
`;

const StyledTypography = styled.p`
  color: ${Colors.Primary};
`;

const Description = styled.p<{
  size?: string;
  color?: string;
  weight?: string;
}>`
  font-size: ${(props) => (props.size ? props.size : '24px')};
  font-weight: ${(props) => (props.weight ? props.weight : '200')};
  color: #474747;
`;

const Col = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${ScreenSize.small} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const InfoGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 64px;
  padding: 0px;
  font-size: 12px;
  ${ScreenSize.mediumSmall} {
    width: 100%;
    justify-content: space-around;
    margin-right: 0px;
    flex-wrap: wrap;
  }
`;

const IconTextGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

const StyledLocationIcon = styled(LocationOnTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledWorkIcon = styled(WorkTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;

interface InternshipCardProps {
  title: string;
  company: string;
  description: string;
  logo: string;
  location: string;
  domain: string;
  to: string;
}

export const MyInternshipCard = observer(
  ({ title, company, description, logo, location, domain, to }: InternshipCardProps) => {
    const history = useHistory();
    return (
      <StyledContainer>
        <Row>
          <StyledLogo src={logo} />
          <CompanyInfo>
            <Headline size="20px">{title}</Headline>
            <SubHeadline size="16px">{company}</SubHeadline>
          </CompanyInfo>
        </Row>
        <Description size="12px" weight="400">
          {description}
        </Description>
        <CardFooter>
          <Col>
            <InfoGroup>
              <IconTextGroup>
                <StyledLocationIcon fontSize="small" />
                <p>Locatie: </p>
              </IconTextGroup>
              <StyledTypography>{location}</StyledTypography>
            </InfoGroup>
            <InfoGroup>
              <IconTextGroup>
                <StyledWorkIcon fontSize="small" />
                <p>Domeniu: </p>
              </IconTextGroup>
              <StyledTypography>{domain}</StyledTypography>
            </InfoGroup>
          </Col>
          <PrimaryButton onClick={() => history.push(to)} variant="outlined">
            Vizualizează
          </PrimaryButton>
        </CardFooter>
      </StyledContainer>
    );
  }
);
