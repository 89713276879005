import React, { useRef } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Experience } from 'constants/types/Experience';
import { Language } from 'constants/types/Language';
import { Spacer } from 'components/common/Spacer';

const GlobalStyle = createGlobalStyle`
  @page {
    margin: 2cm;
    margin-bottom: 3cm;
    
  }
  @media print
    {
      .page-break {
        display: block;
        break-before: auto;
      }
    }
`;

const SpacerLine = styled.div`
  height: 32px;
  border-top: 1px solid #868686;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const RowLeft = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  ${ScreenSize.mediumSmall} {
    justify-content: center;
  }
  width: 100%;
`;

const SpacedRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px dashed #c0c0c0;
  margin-bottom: 16px;
  ${ScreenSize.mediumSmall} {
    flex-direction: column;
  }
`;

const StyledDiv = styled.div`
  width: 50vw;
  ${ScreenSize.mediumSmall} {
    width: 80vw;
  }
`;

const Container = styled.div`
  width: 50vw;
  ${ScreenSize.mediumSmall} {
    width: 100vw;
    padding: 0px 32px;
  }
`;

const StyledTypography = styled.p`
  color: #868686;
  margin-left: 6px;
  font-weight: 700;
`;

const TextContainer = styled.div`
  display: flex;
`;
interface AutoCompletedFieldProps {
  field?: string;
  information: string;
}

const AutoCompletedField = ({ field, information }: AutoCompletedFieldProps) => (
  <TextContainer>
    <p>{field}:</p>
    <StyledTypography>{information}</StyledTypography>
  </TextContainer>
);

interface ExperienceProps {
  position: string;
  company: string;
  months: number;
  type: string;
}
const ExperienceComponent = ({ position, company, months, type }: ExperienceProps) => (
  <SpacedRow>
    <Column>
      <Row>
        <p>Poziția:</p>
        <StyledTypography>{position}</StyledTypography>
      </Row>
      <Row>
        <p>Firma/Organizația:</p>
        <StyledTypography>{company}t</StyledTypography>
      </Row>
    </Column>
    <Column>
      <Row>
        <p>Numărul de luni:</p>
        <StyledTypography>{months}</StyledTypography>
      </Row>
      <Row>
        <p>Tipul:</p>
        <StyledTypography>{type === 'work' ? 'Experienta de munca' : 'Voluntariat'}</StyledTypography>
      </Row>
    </Column>
  </SpacedRow>
);

interface LanguageProps {
  language: string;
  level: string;
}

const LanguageComponent = ({ language, level }: LanguageProps) => {
  return (
    <SpacedRow>
      <Column>
        <Row>
          <p>Limba:</p>
          <StyledTypography>{language}</StyledTypography>
        </Row>
      </Column>
      <Column>
        <Row>
          <p>Nivel:</p>
          <StyledTypography>{level}</StyledTypography>
        </Row>
      </Column>
    </SpacedRow>
  );
};

interface Props {
  cv: any;
}

export const ApplicantCV = observer(({ cv }: Props) => {
  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Container>
      <GlobalStyle />
      <StyledDiv ref={componentRef}>
        <h2>{cv.name}</h2>
        <h4>Informatii personale</h4>
        <SpacerLine />
        <AutoCompletedField field="Telefon" information={cv.phone} />
        <AutoCompletedField field="Email" information={cv.email} />
        <h4>Studii</h4>
        <SpacerLine />
        <AutoCompletedField field="Facultatea" information={cv.faculty.name} />
        <AutoCompletedField field="Specializarea" information={cv.faculty.major} />
        <AutoCompletedField field="Anul" information={cv.faculty.yearsOfStudy.toString()} />
        <AutoCompletedField field="Liceul absolvit" information={cv.highschool.name} />
        <AutoCompletedField field="Specializarea liceului albsolvit" information={cv.highschool.major} />
        <h4>Despre mine</h4>
        <SpacerLine />
        <p>{cv.description}</p>
        <div className="page-break">
          <h4>Abilitati</h4>
          <ul>
            {cv.abilities.map((ability: string, index: number) => (
              <li key={`abl${index}`}>{ability}</li>
            ))}
          </ul>
        </div>
        <h4>Experienta</h4>
        <SpacerLine />
        <div>
          {cv.experiences.map((experience: Experience, index: number) => (
            <ExperienceComponent
              position={experience.position}
              company={experience.company}
              months={experience.months}
              type={experience.type}
              key={`exp${index}`}
            />
          ))}
        </div>
        <h4>Limbi cunoscute</h4>
        <SpacerLine />
        {cv.languages.map((language: Language, index: number) => (
          <LanguageComponent language={language.language} level={language.level} key={`lan${index}`} />
        ))}
      </StyledDiv>
      <Spacer height={32} />
      <RowLeft>
        <Button variant="contained" onClick={handlePrint}>
          Tipărește CV
        </Button>
      </RowLeft>
    </Container>
  );
});
