import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Routes } from '../../constants/routes';

import InternshipsScreen from './InternshipsScreen';
import ProfileScreen from './ProfileScreen';
import InternshipScreen from './InternshipScreen';
import MyInternshipScreen from './StudentInternshipScreen';
import MyInternshipsScreen from './MyInternships';

export const StudentScreens = () => {
  return (
    <Switch>
      <Route exact path={Routes.Home} component={InternshipsScreen} />
      <Route exact path={Routes.Profile} component={ProfileScreen} />
      <Route path={Routes.Internship} component={InternshipScreen} />
      <Route path={Routes.MyInternship} component={MyInternshipScreen} />
      <Route exact path={Routes.MyInternships} component={MyInternshipsScreen} />
      {/* add redirect here */}
    </Switch>
  );
};
