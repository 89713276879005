import React, { useState, useEffect } from 'react';
import { IconButton, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { FieldArray, Form, Formik, getIn } from 'formik';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Student, STUDENT_INITIAL } from 'models/Student';
import { Routes } from 'constants/routes';

import PrimaryButton from '../buttons/PrimaryButton';
import { TextInput } from '..//control/TextInput';
import { SelectInput } from '../../components/control/inputs/SelectInput';
import { AddCVSchema } from '../../constants/yup/AddCVSchema';
import { AutoCompletedInfo } from '../../components/profile/AutocompletedInformations';
import { MultipleValues } from '../../components/control/MultipleValues';
import { useStore } from 'hooks/useStore';
import { toast } from 'react-toastify';

const Profile = styled.div`
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  height: fit-content;
  align-items: center;
`;

const Spacer = styled.div`
  height: 32px;
`;

const SpacerBorder = styled.div`
  height: 32px;
  border-top: 1px solid #868686;
`;

const ExperienceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  padding: 16px 0px 32px 0px;
  margin-bottom: 16px;
  border-bottom: 1px dashed #868686;
  ${ScreenSize.small} {
    width: 80vw;
  }
`;

const InputWrapper = styled.div`
  width: 45%;
  ${ScreenSize.small} {
    width: 100%;
    margin-bottom: 32px;
  }
`;

const InputsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 160px;
  justify-content: space-between;
`;

const InputsContainer2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const ExperienceRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${ScreenSize.small} {
    flex-direction: column;
  }
`;

const StyledForm = styled(Form)`
  width: 50vw;
  ${ScreenSize.mediumSmall} {
    width: 80vw;
  }
`;

export const CVForm = observer(() => {
  const [formData, setFormData] = useState<any>({});
  const [student, setStudent] = useState<Student>(STUDENT_INITIAL);
  const [course, setCourse] = useState<number>(0);
  const [isEdit, setIsEdit] = useState(false);
  const {
    cvStore: { add, loading, getByStudentId },
  } = useStore();
  const history = useHistory();
  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    console.log('LS USER IN CvForm ', lsUser);
    if (lsUser) {
      const user = JSON.parse(lsUser);
      setStudent(user.student);
      setCourse(parseInt(user.cursuri[0].curs));

      (async () => {
        await getByStudentId(parseInt(user.student.id));
      })();
    } else {
    }
  }, [isEdit]);
  return (
    <Profile>
      <AutoCompletedInfo
        name={student.nume}
        phone={student.telefon}
        email={student.email}
        faculty={student.den_facultate}
        major={student.den_specializare}
        yearOfStudy={student.an}
        highschool={student.liceu_denumire}
        highschoolMajor={student.liceu_specializare}
      />
      <Formik
        initialValues={{
          description: '',
          abilities: [],
          experiences: [
            {
              position: '',
              company: '',
              months: '',
              type: '',
            },
          ],
          languages: [
            {
              language: '',
              level: '',
            },
          ],
        }}
        validationSchema={AddCVSchema}
        onSubmit={async (values) => {
          const lsUser = localStorage.getItem('user');
          if (lsUser) {
            const user = JSON.parse(lsUser);
            (async () => {
              getByStudentId(parseInt(user.student.id));
            })();
          }
          const res = await add({
            name: student.nume,
            studentId: parseInt(student.id),
            courseId: course,
            phone: student.telefon,
            universityYear: parseInt(student.an_universitar),
            email: student.email,
            faculty: {
              name: student.den_facultate,
              university: 'Universitatea "Aurel Vlaicu"',
              major: student.den_specializare,
              yearsOfStudy: parseInt(student.an),
              year: 2023,
            },
            graduatedFaculties: [],
            highschool: {
              name: student.liceu_denumire,
              major: student.liceu_specializare,
              year: parseInt(student.liceu_an),
            },
            ...values,
            abilities: formData.abilities,
          });
          history.push(Routes.Profile);
          if (res.status === 201) {
            toast.success('CV-ul a fost adăugat cu succes');
          } else {
            toast.error('A fost o problemă cu adăugarea CV-ului');
          }
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit }) => (
          <StyledForm noValidate autoComplete="off">
            <TextField
              fullWidth
              id="description"
              name="description"
              label="Despre mine"
              multiline
              rows={4}
              onChange={handleChange}
              value={values.description}
            />
            <Spacer />
            <MultipleValues
              onChange={(e, value) => {
                setFormData({ ...formData, abilities: value });
              }}
              label={'Abilități personale'}
              placeholder={'Introduceți o abilitate personală si apăsați Enter'}
            />
            <Spacer />
            <FieldArray name="experiences">
              {({ push, remove }) => (
                <div>
                  <Row>
                    <h4>Experienta</h4>
                    <IconButton
                      onClick={() =>
                        push({
                          position: '',
                          company: '',
                          months: '',
                          type: '',
                        })
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Row>
                  <SpacerBorder />
                  {values.experiences.map((p, index) => {
                    const position = `experiences[${index}].position`;
                    const touchedPosition = getIn(touched, position);
                    const errorPosition = getIn(errors, position);

                    const company = `experiences[${index}].company`;
                    const touchedCompany = getIn(touched, company);
                    const errorCompany = getIn(errors, company);

                    const months = `experiences[${index}].months`;
                    const touchedNumberOfMonths = getIn(touched, months);
                    const errorNumberOfMonths = getIn(errors, months);

                    const type = `experiences[${index}].type`;
                    const touchedType = getIn(touched, type);
                    const errorType = getIn(errors, type);

                    return (
                      <ExperienceContainer key={index}>
                        <InputsContainer>
                          <ExperienceRow>
                            <InputWrapper>
                              <TextInput
                                label="Pozitia"
                                name={position}
                                value={p.position}
                                required
                                helperText={touchedPosition && errorPosition ? errorPosition : ''}
                                error={Boolean(touchedPosition && errorPosition)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <TextInput
                                label="Compania"
                                name={company}
                                value={p.company}
                                required
                                helperText={touchedCompany && errorCompany ? errorCompany : ''}
                                error={Boolean(touchedCompany && errorCompany)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </InputWrapper>
                          </ExperienceRow>
                          <ExperienceRow>
                            <InputWrapper>
                              <TextInput
                                label="Numar de luni"
                                name={months}
                                value={p.months}
                                required
                                helperText={
                                  touchedNumberOfMonths && errorNumberOfMonths ? errorNumberOfMonths : ''
                                }
                                error={Boolean(touchedNumberOfMonths && errorNumberOfMonths)}
                                onChange={handleChange}
                                type="number"
                                onBlur={handleBlur}
                              />{' '}
                            </InputWrapper>

                            <InputWrapper>
                              <SelectInput
                                label="Tip"
                                labelId="label"
                                id="demo-simple-select"
                                name={type}
                                value={p.type}
                                required
                                error={Boolean(touchedType && errorType)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperId="select-helper-text"
                                helperText={touchedType && errorType ? errorType : ''}
                                menuOptions={[
                                  { value: '', text: 'Niciuna' },
                                  { value: 'volunteering', text: 'Voluntariat' },
                                  { value: 'work', text: 'Experienta de munca' },
                                ]}
                              />{' '}
                            </InputWrapper>
                          </ExperienceRow>
                        </InputsContainer>
                        <IconButton style={{ marginLeft: '16px' }} onClick={() => remove(index)}>
                          <CloseIcon fontSize="medium" />
                        </IconButton>
                      </ExperienceContainer>
                    );
                  })}
                </div>
              )}
            </FieldArray>
            <Spacer />
            <FieldArray name="languages">
              {({ push, remove }) => (
                <div>
                  <Row>
                    <h4>Limbi cunoscute</h4>
                    <IconButton
                      onClick={() =>
                        push({
                          position: '',
                          company: '',
                          numberOfMonths: '',
                          type: '',
                        })
                      }
                    >
                      <AddIcon />
                    </IconButton>
                  </Row>
                  <SpacerBorder />
                  {values.languages.map((p, index) => {
                    const language = `languages[${index}].language`;
                    const touchedLanguage = getIn(touched, language);
                    const errorLanguage = getIn(errors, language);

                    const level = `languages[${index}].level`;
                    const touchedLevel = getIn(touched, level);
                    const errorLevel = getIn(errors, level);

                    return (
                      <ExperienceContainer key={index}>
                        <InputsContainer2>
                          <ExperienceRow>
                            <InputWrapper>
                              <TextInput
                                label="Limba"
                                name={language}
                                value={p.language}
                                required
                                helperText={touchedLanguage && errorLanguage ? errorLanguage : ''}
                                error={Boolean(touchedLanguage && errorLanguage)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </InputWrapper>
                            <InputWrapper>
                              <SelectInput
                                label="Nivel"
                                labelId="label-level"
                                id="demo-simple-select-level"
                                name={level}
                                value={p.level}
                                required
                                error={Boolean(touchedLevel && errorLevel)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                helperId="select-helper-text-level"
                                helperText={touchedLevel && errorLevel ? errorLevel : ''}
                                menuOptions={[
                                  { value: 'Matern', text: 'Matern' },
                                  { value: 'A1', text: 'A1' },
                                  { value: 'A2', text: 'A2' },
                                  { value: 'B1', text: 'B1' },
                                  { value: 'B2', text: 'B2' },
                                  { value: 'C1', text: 'C1' },
                                  { value: 'C2', text: 'C2' },
                                ]}
                              />
                            </InputWrapper>
                          </ExperienceRow>
                        </InputsContainer2>
                        <IconButton style={{ marginLeft: '16px' }} onClick={() => remove(index)}>
                          <CloseIcon fontSize="medium" />
                        </IconButton>
                      </ExperienceContainer>
                    );
                  })}
                </div>
              )}
            </FieldArray>

            <PrimaryButton type="submit" onClick={handleSubmit} variant="contained">
              Salveaza
            </PrimaryButton>
          </StyledForm>
        )}
      </Formik>
    </Profile>
  );
});
