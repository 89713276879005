import autoBind from 'auto-bind';
import { makeAutoObservable } from 'mobx';

import { AppStateStore } from './AppStateStore';
import { AuthStore } from './AuthStore';
import { CompanyStore } from './CompanyStore';
import { CVStore } from './CVStore';
import { InternshipStore } from './InternshipStore';
import { ReviewStore } from './ReviewStore';
import { StudentFileStore } from './StudentFileStore';
import { CVHelpStore } from './CVHelpStore';
import { ApplicantFileStore } from './ApplicantFileStore';

export class RootStore {
  appState = new AppStateStore();
  companyStore: CompanyStore;
  authStore: AuthStore;
  internshipStore: InternshipStore;
  cvStore: CVStore;
  reviewStore: ReviewStore;
  studentFileStore: StudentFileStore;
  cvHelpStore: CVHelpStore;
  applicantFileStore: ApplicantFileStore;

  constructor() {
    makeAutoObservable(this);
    autoBind(this);

    this.companyStore = new CompanyStore(this.appState.companyApi);
    this.authStore = new AuthStore(this.appState.authApi);
    this.internshipStore = new InternshipStore(this.appState.internshipApi);
    this.cvStore = new CVStore(this.appState.cvApi);
    this.reviewStore = new ReviewStore(this.appState.reviewApi);
    this.studentFileStore = new StudentFileStore(this.appState.studentFileApi);
    this.cvHelpStore = new CVHelpStore(this.appState.cvHelpApi);
    this.applicantFileStore = new ApplicantFileStore(this.appState.applicantFileApi);
  }
}
