import { makeAutoObservable, runInAction } from 'mobx';
import { StudentFileApi } from '../api/StudentFileApi';
import axios from 'axios';

export class StudentFileStore {
  api: StudentFileApi;
  files: any[] = [];
  loading: boolean = false;

  constructor(api: StudentFileApi) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.api = api;
  }

  async listByCourseId(id: number) {
    this.loading = true;
    const res = await this.api.listByCourseId(id);
    runInAction(() => {
      this.loading = false;
      this.files = res.data;
    });
    return res;
  }

  async listByCvId(id: string) {
    this.loading = true;
    const res = await this.api.listByCvId(id);
    runInAction(() => {
      this.loading = false;
      this.files = res.data;
    });
    return res;
  }

  getReviewByInternship(id: string, studId: number) {
    return axios.get(`${process.env.REACT_APP_API_URL}/review/student/${studId}/internship/${id}`);
  }
}
