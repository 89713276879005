import log from 'loglevel';
import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { omit } from 'lodash';

import { AuthApi } from '../api/AuthApi';
import { verify } from '../services/jwt';

export class AuthStore {
  api: AuthApi;
  loading: boolean = false;

  // persiset props
  access_token: any;
  user: any;

  constructor(api: AuthApi) {
    this.api = api;

    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: 'AuthStore',
      properties: ['access_token', 'user'],
      storage: window.localStorage,
    });
  }

  getToken() {
    return this.access_token;
  }

  async login(email: string, password: string) {
    log.info('This is a random log');
    this.loading = true;
    const res = await this.api.login(email, password);

    runInAction(() => {
      if (res && res.data.access_token) {
        const payload = verify(res.data.access_token) as any;
        const loggedUser = payload;

        localStorage.setItem('user', JSON.stringify(omit(loggedUser, ['password'])));
        localStorage.setItem('access_token', res.data.access_token);

        this.access_token = res.data.access_token;
        if (loggedUser && typeof loggedUser === 'object') {
          this.user = omit(loggedUser, ['password']);
          localStorage.setItem('user', JSON.stringify(omit(loggedUser, ['password'])));
        }

        this.loading = false;
      }
    });
    return res;
  }

  logout() {
    this.access_token = '';
    localStorage.removeItem('access_token');
  }
}
