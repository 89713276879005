import { ApiConfig } from './ApiConfig';

export class StudentFileApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  listByCourseId(id: number) {
    return this.apiConfig.sendRequest('GET', `/file/course/${id}`, null);
  }
  listByCvId(id: string) {
    return this.apiConfig.sendRequest('GET', `/file/cv/${id}`, null);
  }
}
