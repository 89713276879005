import autoBind from 'auto-bind';
import { makeAutoObservable, runInAction } from 'mobx';

import { CompanyApi } from '../api/CompanyApi';
import { CompanyDocument, CompanyModel, CompanyStatus } from '../models/Company';
import { INITIAL_COMPANY } from 'constants/initial/InitialCompany';
import { toast } from 'react-toastify';

export class CompanyStore {
  api: CompanyApi;
  companies: CompanyDocument[] = [];
  current: CompanyDocument = INITIAL_COMPANY;
  loading: boolean = false;

  constructor(api: CompanyApi) {
    makeAutoObservable(this);
    autoBind(this);

    this.api = api;
  }

  async create(data: Omit<CompanyModel, 'role'>) {
    const res = await this.api.create(data);
    if (res.status === 201 || res.status === 200)
      toast.success('Contul dumneavoastra a fost creat!', { position: 'top-center' });
    else toast.error('A aparut o problema. Va rugam incercati mai tarziu.', { position: 'top-center' });
  }

  async list() {
    this.loading = true;
    const res = await this.api.list();

    runInAction(() => {
      this.companies = res.data;
      this.loading = false;
    });

    return res;
  }

  async getOne(id: string) {
    this.loading = true;
    const res = await this.api.getOne(id);
    runInAction(() => {
      this.loading = false;
      this.current = res.data;
    });
    return res;
  }

  async update(id: string, data: Partial<CompanyModel>) {
    const res = await this.api.update(id, data);

    if (res.status !== 200) return toast.error('A apărut o problemă! Vă rugăm încercați mai târziu.');

    if (data.status === CompanyStatus.Approved) toast.success('AAME aprobat cu succes!');
    else toast.success('Companie respinsă!');

    runInAction(() => {
      const copy = this.companies;
      const index = copy.map((c) => c._id).indexOf(id);
      if (data.status) copy[index].status = data.status;
      this.companies = [...copy];
    });
  }
}
