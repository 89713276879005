export const MAJORS = [
  { name: 'Design Ambiental	', faculty: 3 },
  { name: 'Design', faculty: 3 },
  { name: 'Modă - design vestimentar', faculty: 3 },
  { name: 'Educaţie fizică şi sportivă', faculty: 4 },
  { name: 'Kinetoterapie și Motricitate Specială', faculty: 4 },
  { name: 'Inginerie economică industrială', faculty: 7 },
  { name: 'Tehnologia şi designul produselor textile', faculty: 7 },
  { name: 'Tehnologia tricotajelor şi confecţiilor', faculty: 7 },
  { name: 'Autovehicule rutiere', faculty: 7 },
  { name: 'Tehnologia construcţiilor de maşini', faculty: 7 },
  { name: 'Ingineria sudării', faculty: 7 },
  { name: 'Automatică şi informatică aplicată', faculty: 7 },
  { name: 'Controlul şi expertiza produselor alimentare', faculty: 1 },
  { name: 'Inginerie şi management în alimentaţia publică şi agroturism', faculty: 1 },
  { name: 'Ingineria produselor alimentare', faculty: 1 },
  { name: 'Ingineria sistemelor biotehnice şi ecologice', faculty: 1 },
  { name: 'Protecţia consumatorului si a mediului	', faculty: 1 },
  { name: 'Asistenţă sociala', faculty: 5 },
  { name: 'Pedagogia învăţământului primar şi preşcolar', faculty: 5 },
  { name: 'Psihopedagogie specială', faculty: 5 },
  { name: 'Psihologie', faculty: 5 },
  { name: 'Administrarea Afacerilor', faculty: 2 },
  { name: 'Contabilitate şi informatică de gestiune', faculty: 2 },
  { name: 'Economia comerţului, turismului şi serviciilor', faculty: 2 },
  { name: 'Finanţe şi bănci', faculty: 2 },
  { name: 'Management', faculty: 2 },
  { name: 'Marketing', faculty: 2 },
  { name: 'Informatică', faculty: 8 },
  { name: 'Informatică aplicată (în limba engleză)', faculty: 8 },
  { name: 'Matematică informatică', faculty: 8 },
  { name: 'Informatică (în limba engleză)', faculty: 8 },
  { name: 'Jurnalism', faculty: 9 },
  { name: 'Administraţie publică', faculty: 9 },
  { name: 'Limba şi literatura română - limba şi literatura engleză', faculty: 9 },
  { name: 'Teologie penticostală didactică', faculty: 9 },
  { name: 'Limbă şi literatura română - limbă şi literatură germană', faculty: 9 },
  { name: 'Muzică', faculty: 9 },
  { name: 'Drept european și internațional', faculty: 9 },
  { name: 'Modul facultativ canto/instrument', faculty: 9 },
  { name: 'Teologie ortodoxă pastorală	', faculty: 6 },
];
