export const INITIAL_COMPANY_REGISTRATION = {
  name: '',
  address: '',
  fiscalCode: '',
  registrationCode: '',
  email: '',
  phone: '',
  established: '',
  websiteUrl: '',
  description: '',
  password: '',
  confirmPassword: '',
};
