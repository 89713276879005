import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import styled from 'styled-components';
import { IconButton } from '@mui/material';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Filters } from 'constants/style/Colors';

import PrimaryButton from 'components/buttons/PrimaryButton';
import { useStore } from 'hooks/useStore';

const Spacer = styled.div`
  height: 32px;
`;

const StyledClose = styled(CloseIcon)`
  color: #fff;
`;

const IconTextButton = styled(IconButton)`
  display: flex;
`;
const StyledFileIcon = styled(InsertDriveFileTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Blue};
`;
const IconTextGroup = styled.div`
  display: flex;
  align-items: center;
`;
const StyledUpload = styled(FileUploadIcon)`
  margin-right: 8px;
  font-size: 2px;
`;
const ContainerWithBorder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTypography = styled.p`
  font-size: 12px;
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  height: '45vh',
};
interface Props {
  open: boolean;
  handleClose: () => void;
  studentId: number;
  cvId: string;
  companyId: string;
  internshipId: string;
  courseId: number;
}
export default function ApplicantReviewModal({ open, handleClose, cvId, internshipId }: Props) {
  const {
    applicantFileStore: { listByCvId },
  } = useStore();

  const hiddenFileInput = React.useRef(null);
  const [file, setFile] = useState<any>();
  useEffect(() => {
    (async () => {
      const res = await listByCvId(cvId);
      setFile({ name: res.data[res.data.length - 1].filename, id: res.data[res.data.length - 1].fileId });
    })();
  }, []);

  const handleButtonClick = () => {
    /* @ts-ignore */
    hiddenFileInput!.current.click();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div>
        <IconButton sx={{ position: 'absolute', top: '24%', right: '18%' }} onClick={handleClose}>
          <StyledClose />
        </IconButton>
        <Paper sx={style}>
          <ContainerWithBorder>
            <h2>Adeverința de practică</h2>
            <IconTextButton onClick={handleButtonClick}>
              <StyledUpload fontSize="medium" />
              <StyledTypography>ÎNCARCĂ ADEVERINȚA DE PRACTICĂ</StyledTypography>
            </IconTextButton>
            <input
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              type={'file'}
              onChange={(event) => {
                if (event.target.files) {
                  setFile(event.target.files[0]);
                }
              }}
            />
          </ContainerWithBorder>
          <Spacer />
          <Row>
            <IconTextGroup>
              <StyledFileIcon fontSize="medium" />
              {file && (
                <a
                  href={`${process.env.REACT_APP_API_URL}/file/download/${file.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {file.name || ''}
                </a>
              )}
            </IconTextGroup>

            <PrimaryButton
              full={false}
              onClick={async () => {
                const data = new FormData();
                data.append('file', file);
                try {
                  const response = await axios.post(`${process.env.REACT_APP_API_URL}/file/`, data, {});
                  const studFile = await axios.post(`${process.env.REACT_APP_API_URL}/file/applicant_file/`, {
                    fileId: response.data.id,
                    filename: file.name,
                    cvId: cvId,
                    internshipId: internshipId,
                  });
                  if (studFile.status === 201) {
                    toast.success(`Adeverinta de practica incarcata cu succes!`);
                    setFile({ name: file.name, id: response.data.id });
                  }
                } catch (e) {
                  console.log(e);
                  toast.error('A aparut o problema. Va rugam incercati mai tarziu!');
                }
              }}
            >
              salveaza
            </PrimaryButton>
          </Row>
        </Paper>
      </div>
    </Modal>
  );
}
