import { CVApi } from 'api/CVApi';
import autoBind from 'auto-bind';
import { makeAutoObservable, runInAction } from 'mobx';
import { CvDocument, CvDto } from 'models/CV';
import { INITIALCV } from 'constants/initial/InitialCv';

export class CVStore {
  api: CVApi;
  CVs: any[] = [];
  current: CvDocument | undefined = INITIALCV;
  loading: boolean = false;

  constructor(api: CVApi) {
    makeAutoObservable(this);
    autoBind(this);

    this.api = api;
  }

  async add(data: CvDto) {
    this.loading = true;
    const doc = await this.api.add(data);

    runInAction(() => {
      this.loading = false;
    });

    return doc;
  }

  async getByStudentId(studentId: number) {
    this.loading = true;
    const res = await this.api.getByStudentId(studentId);
    runInAction(() => {
      this.loading = false;
      if (res.data === '') this.current = undefined;
      else this.current = res.data;
    });
    return res;
  }

  async update(id: string, data: Omit<CvDto, 'companyId'>) {
    this.loading = true;
    const res = await this.api.update(id, data);
    runInAction(() => {
      this.loading = false;
      this.current = res.data;
    });
    return res;
  }

  async listByCourse(courseId: number) {
    this.loading = true;
    const res = await this.api.listByCourse(courseId);
    runInAction(() => {
      this.loading = false;
      this.CVs = res.data;
    });
    return res;
  }
}
