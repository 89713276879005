import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';

import { Colors } from 'constants/style/Colors';
import { MAJORS } from 'constants/enums/Majors';

import AppTable from 'components/table/AppTable';
import { useStore } from 'hooks/useStore';
import { ProfessorFilters } from 'components/control/ProfessorFilters';
import StudentHelpRow from 'components/rows/StudentHelpRow';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
`;

const WhiteContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${Colors.White};
  padding: 24px;
`;

const TableColums = [
  { name: 'Nume', width: 20 },
  { name: 'Cod student', width: 20 },
  { name: 'Programul de studii', width: 20 },
  { name: 'Email ', width: 20 },
];

const StudentsRequestHelpScreen = () => {
  const [searchName, setSearchName] = useState('');
  const [universityYear, setUniversityYear] = useState(10);
  const [studyYear, setStudyYear] = useState(1);
  const [majors, setMajors] = useState<any[]>([]);
  const [searchMajors, setSearchMajors] = useState([]);
  const [studs, setStuds] = useState<any>([]);
  const {
    cvHelpStore: { listByCourseId, students },
  } = useStore();

  const componentRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      const userFaculties: number[] = Object.keys(user.facultatea).map((f) => parseInt(f));
      const maj = MAJORS.filter((m) => {
        return userFaculties.includes(m.faculty);
      });
      setMajors(maj);
      (async () => {
        const res = await listByCourseId(parseInt(JSON.parse(lsUser).cursuri[0].curs));
        setStuds(res.data);
      })();
    }
  }, []);

  useEffect(() => {
    let filtered = students.filter((f: any) => {
      for (let i = 0; i < searchMajors.length; i++) if (searchMajors[i] === f.faculty.major) return true;
      return false;
    });
    if (searchMajors.length === 0) filtered = students;
    filtered = filtered.filter((f: any) => {
      let name = f.name.toLowerCase();
      name = name.replace(new RegExp(/[àáâăãäå]/g), 'a');
      name = name.replace(new RegExp(/æ/g), 'ae');
      name = name.replace(new RegExp(/ç/g), 'c');
      name = name.replace(new RegExp(/ș/g), 's');
      name = name.replace(new RegExp(/[èéêë]/g), 'e');
      name = name.replace(new RegExp(/[ìíîï]/g), 'i');
      name = name.replace(new RegExp(/ñ/g), 'n');
      name = name.replace(new RegExp(/[òóôõö]/g), 'o');
      name = name.replace(new RegExp(/œ/g), 'oe');
      name = name.replace(new RegExp(/[ùúûü]/g), 'u');
      name = name.replace(new RegExp(/[ýÿ]/g), 'y');
      const nameWords = searchName.toLowerCase().split(' ');
      if (nameWords[0] === '') return true;
      for (let i = 0; i < nameWords.length; i++)
        if (nameWords[i] !== '' && name.includes(nameWords[i])) return true;
      return false;
    });
    filtered = filtered.filter((f: any) => {
      if (f.cvId.universityYear === universityYear) return true;
      return false;
    });
    filtered = filtered.filter((f: any) => {
      if (f.cvId.faculty.yearsOfStudy === studyYear) return true;
      return false;
    });
    setStuds(filtered);
  }, [searchName, universityYear, studyYear, searchMajors]);

  return (
    <StyledContainer>
      <WhiteContainer>
        <ProfessorFilters
          nameValue={searchName}
          nameOnChange={(e) => setSearchName(e.target.value)}
          majorsValue={searchMajors}
          majorsOnChange={setSearchMajors}
          majors={majors}
          universityYear={universityYear}
          universityYearOnChange={(e) => setUniversityYear(e.target.value)}
          studyYear={studyYear}
          studyYearOnChange={(e) => setStudyYear(e.target.value)}
        />
        <Button sx={{ marginTop: '16px' }} variant="contained" onClick={handlePrint}>
          Tipărește lista
        </Button>
      </WhiteContainer>
      <WhiteContainer ref={componentRef}>
        <AppTable tableHeaderColumnNames={TableColums}>
          {studs.map((stud: any) => (
            <StudentHelpRow
              key={stud._id}
              name={stud.name}
              studentId={stud.studentId.toString()}
              email={stud.email}
              major={stud.faculty.major}
            />
          ))}
        </AppTable>
      </WhiteContainer>
    </StyledContainer>
  );
};

export default observer(StudentsRequestHelpScreen);
