import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Colors } from 'constants/style/Colors';

import AppTable from 'components/table/AppTable';
import ApplicantRow from 'components/rows/ApplicantRow';
import { useStore } from 'hooks/useStore';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
`;

const WhiteContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${Colors.White};
  padding: 24px;
`;

const TableColums = [
  { name: 'Nume', width: 20 },
  { name: 'Pozitia', width: 20 },
  { name: 'CV ', width: 20 },
  { name: 'Status ', width: 20 },
  { name: 'Actiuni ', width: 20, align: 'center' },
];

enum CompanyStatuses {
  Accepted = 'approved',
  Pending = 'pending',
  Declined = 'declined',
}

const title = ['Programare web', 'Practica in contabilitate'];

const DashboardApplicantsListScreen = () => {
  const {
    cvStore: { CVs },
  } = useStore();

  useEffect(() => {}, []);

  const status: CompanyStatuses[] = [CompanyStatuses.Accepted, CompanyStatuses.Pending];
  return (
    <StyledContainer>
      <WhiteContainer>
        <AppTable tableHeaderColumnNames={TableColums}>
          {CVs.map((cv, index) => (
            <ApplicantRow
              companyId="0"
              internshipId="0"
              key={index}
              cv={cv}
              internshipTitle={title[index]}
              status={status[index]}
            />
          ))}
        </AppTable>
      </WhiteContainer>
    </StyledContainer>
  );
};

export default observer(DashboardApplicantsListScreen);
