import React from 'react';
import styled from 'styled-components';
import { experimentalStyled as MUIstyled } from '@mui/material/styles';
import FilterAltTwoToneIcon from '@mui/icons-material/FilterAltTwoTone';
import { IconButton } from '@mui/material';

import { ScreenSize } from '../../constants/responsive/MediaQueries';

import { SearchInput } from '../control/inputs/SearchInput';
import { DrawerFilter } from '../control/DrawerFilter';

const SearchWrapper = styled.div`
  width: 720px;
  ${ScreenSize.mediumSmall} {
    width: 100%;
  }
`;

const SearchArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px dashed #e3e6ef;
  margin-bottom: 16px;
  margin-top: 16px;
  padding: 0 20px 20px 20px;

  ${ScreenSize.mediumSmall} {
    width: 100%;
  }
`;

const Container = styled.div`
  display: none;

  ${ScreenSize.mediumSmall} {
    display: flex;
    align-items: center;
    align-self: flex-start;
    margin-left: 0;
  }
`;

const Typography = styled.p`
  margin: 16px 0px;
  font-size: 13px;
  font-weight: 500;
  color: #7e8299;
  align-self: flex-start;
`;

const StyledIconButton = MUIstyled(IconButton)`
  padding: 0;
`;

interface Props {
  value: string;
  onChange: (e: any) => void;
}

export const SearchComponent = ({ value, onChange }: Props) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <SearchArea>
      <SearchWrapper>
        <SearchInput value={value} onChange={onChange} />
      </SearchWrapper>
      <Container>
        <StyledIconButton size="medium" onClick={handleDrawerOpen}>
          <FilterAltTwoToneIcon fontSize="medium" />
          <Typography>Filtreaza</Typography>
        </StyledIconButton>
      </Container>
      <DrawerFilter handleDrawerClose={handleDrawerClose} open={open} />
    </SearchArea>
  );
};
