import React from 'react';
import styled from 'styled-components';

import SmartDivision from '../../assets/images/smart-division.png';
import { ScreenSize } from '../../constants/responsive/MediaQueries';

const Logo = styled.img`
  max-width: 80px;
  height: auto;
  display: block;
`;

const Container = styled.div<{ fixed?: boolean }>`
  width: 100%;
  height: 80px;
  padding: 16px;
  display: flex;
  background-color: #fff;
  position: ${(props) => (props.fixed ? 'fixed' : 'auto')};
  ${(props) => (props.fixed ? 'bottom: 0' : '')};
  margin-top: 16px;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e4e6ef;

  ${ScreenSize.mediumSmall} {
    flex-direction: column;
    justify-content: flex-start;
    height: 120px;
    padding: 0px 64px;
    position: auto;
  }
`;
interface FooterProps {
  fixed?: boolean;
}
export const Footer = ({ fixed }: FooterProps) => (
  <Container fixed={fixed}>
    <p>© 2021 Smart Division</p>
    <Logo src={SmartDivision} alt="logo" />
  </Container>
);

Footer.defaultProps = {
  fixed: false,
};
