import React from 'react';
import styled from 'styled-components';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import { observer } from 'mobx-react-lite';

import { Colors, Filters } from '../../../constants/style/Colors';
import { ScreenSize } from '../../../constants/responsive/MediaQueries';

const StyledContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  padding: 16px 32px;

  ${ScreenSize.mediumSmall} {
    width: 100%;
    border-right: none;
    margin-right: 0px;
  }
`;

const InternshipHeader = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InformationRow = styled.div`
  display: flex;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e6ef;

  ${ScreenSize.mediumSmall} {
    flex-direction: column;
  }
`;
const InfoGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  margin-right: 64px;
  min-width: 25%;

  ${ScreenSize.mediumSmall} {
    width: 100%;
    justify-content: space-around;
    margin-right: 0px;
    flex-wrap: wrap;
  }
`;

const IconTextGroup = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding-bottom: 16px;
`;

const StyledLocationIcon = styled(LocationOnTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledWorkIcon = styled(WorkTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;

const CompanyName = styled.p`
  margin: 0px;
  color: ${Colors.Grey};
`;

const Title = styled.h1`
  margin: 8px 0px;
`;

const StyledTypography = styled.p`
  color: ${Colors.Primary};
`;

interface InternshipDetailsProps {
  title: string;
  location: string;
  domain: string;
  children: React.ReactNode;
  company: string;
}

export const MyInternshipDetails = observer(
  ({ title, location, domain, children, company }: InternshipDetailsProps) => (
    <StyledContainer>
      <InternshipHeader>
        <TitleContainer>
          <Title>{title}</Title>
          <CompanyName>{company}</CompanyName>
        </TitleContainer>
        <InformationRow>
          <InfoGroup>
            <IconTextGroup>
              <StyledLocationIcon fontSize="medium" />
              <p>Locatie: </p>
            </IconTextGroup>
            <StyledTypography>{location}</StyledTypography>
          </InfoGroup>
          <InfoGroup>
            <IconTextGroup>
              <StyledWorkIcon fontSize="medium" />
              <p>Domeniu: </p>
            </IconTextGroup>
            <StyledTypography>{domain}</StyledTypography>
          </InfoGroup>
        </InformationRow>
      </InternshipHeader>
      <Description>{children}</Description>
    </StyledContainer>
  )
);
