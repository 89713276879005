import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { AppBar } from '../../components/navigation/AppBar';
import { Footer } from '../../components/footer/Footer';
import { CVForm } from '../../components/forms/CVForm';
import { useStore } from 'hooks/useStore';
import { ApplicantCV } from '../../components/applicant/ApplicantCV';
import { CVHelpForm } from 'components/forms/CVHelpForm';

const CenterContainer = styled.div`
  display: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`;

const CenteredContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  height: fit-content;
`;

const ProfileScreen = () => {
  const {
    cvStore: { getByStudentId, current },
    cvHelpStore,
  } = useStore();
  const [sent, setSent] = useState(false);

  useEffect(() => {
    const lsUser = localStorage.getItem('user');

    if (lsUser) {
      const user = JSON.parse(lsUser);
      (async () => {
        getByStudentId(parseInt(user.student.id));
      })();

      (async () => {
        await cvHelpStore.listByStudentId(parseInt(user.student.id));
        if (cvHelpStore.current !== undefined) setSent(true);
      })();
    } else {
    }
  }, []);

  const handleHelpRequest = async () => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      await cvHelpStore.add({
        name: user.student.nume,
        email: user.student.email,
        phone: user.student.telefon,
        studentId: parseInt(user.student.id),
        courseId: parseInt(user.cursuri[0].curs),
        faculty: {
          name: user.student.den_facultate,
          university: 'Universitatea "Aurel Vlaicu"',
          major: user.student.den_specializare,
          yearsOfStudy: parseInt(user.student.an),
          year: 2023,
        },
      });
      setSent(true);
    }
  };

  return (
    <CenterContainer>
      <AppBar />
      {current === undefined ? (
        <div>
          <CVHelpForm sent={sent} handleSent={() => handleHelpRequest()} />
          <CVForm />
        </div>
      ) : (
        <CenteredContainer>
          <ApplicantCV cv={current} />
        </CenteredContainer>
      )}
      <Footer />
    </CenterContainer>
  );
};

export default observer(ProfileScreen);
