import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface Props {
  onChange: (e: string) => void;
  initialValue?: string;
}

export const TinyEditor = ({ onChange, initialValue }: Props) => {
  const editorRef = useRef<any>();

  const handleEditorChange = () => {
    onChange(editorRef.current.getContent());
  };

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINY_API_KEY}
      onInit={(_evt, editor) => (editorRef.current = editor)}
      initialValue={initialValue}
      onKeyDown={handleEditorChange}
      init={{
        branding: false,
        height: 500,
        width: '100%',
        menubar: 'insert',
        plugins: [
          'advlist autolink lists link image',
          'charmap print preview anchor help',
          'searchreplace visualblocks code',
          'insertdatetime media table paste wordcount',
        ],
        toolbar:
          'forecolor backcolor  | undo redo | formatselect | bold italic | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | help',
      }}
      onChange={handleEditorChange}
    />
  );
};
