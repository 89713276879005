export const INITIALCV = {
  _id: '',
  phone: '',
  studentId: 0,
  courseId: 0,
  universityYear: 10,
  email: '',
  name: '',
  faculty: { name: '', university: '', major: '', yearsOfStudy: 0, year: 0 },
  graduatedFaculties: [],
  highschool: { name: '', major: '', year: 0 },
  description: 'ok',
  abilities: [],
  experiences: [
    {
      position: '',
      company: '',
      months: '',
      type: '',
    },
  ],
  languages: [
    {
      language: '',
      level: '',
    },
  ],
};
