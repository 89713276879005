import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Routes } from '../constants/routes';

import LoginPage from '../pages/auth/LoginPage';
import RegisterPage from '../pages/auth/RegisterPage';
import PrivateRoutes from './PrivateRoutes';
import { AutoLoginPage } from '../pages/auth/AutoLogin';

const AppRoutes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={Routes.AutoLogin} component={AutoLoginPage} />
        <Route exact path={Routes.Register} component={RegisterPage} />
        <Route exact path={Routes.Login} component={LoginPage} />
        <PrivateRoutes />
      </Switch>
    </React.Fragment>
  );
};

export default observer(AppRoutes);
