import React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Drawer from '@mui/material/Drawer';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { DOMAINS } from '../../constants/domains/Domains';

import { CategoryCheckbox } from './CategoryCheckbox';

const DrawerHeader = styled.div`
  display: none;
  ${ScreenSize.mediumSmall} {
    padding: 16px 16px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const Container = styled.div`
  padding: 0px 16px;
`;

const StyledTypography = styled.p`
  color: #7e8299;
  font-size: 16px;
`;

interface DrawerFilterProps {
  open: boolean;
  handleDrawerClose: () => void;
}

export const DrawerFilter = ({ handleDrawerClose, open }: DrawerFilterProps) => (
  <Drawer
    onClose={handleDrawerClose}
    sx={{
      '& .MuiDrawer-paper': {
        width: '240px',
      },
    }}
    variant="temporary"
    anchor="left"
    open={open}
  >
    <DrawerHeader>
      <IconButton onClick={handleDrawerClose}>
        <ChevronLeftIcon style={{ height: '64px', color: 'black' }} />
      </IconButton>
    </DrawerHeader>
    <Container>
      <StyledTypography>Filtreaza dupa domenii</StyledTypography>
      {DOMAINS.map((domain: any) => {
        if (domain.faculty === 8) return <CategoryCheckbox key={domain.id}>{domain.domain}</CategoryCheckbox>;
        return null;
      })}
    </Container>
  </Drawer>
);
