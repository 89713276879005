import React from 'react';
import styled from 'styled-components';

import { ScreenSize } from '../../constants/responsive/MediaQueries';

const Spacer = styled.div`
  height: 32px;
  border-top: 1px solid #868686;
`;

const StyledDiv = styled.div`
  width: 50vw;
  ${ScreenSize.small} {
    width: 80vw;
  }
`;

const StyledTypography = styled.p`
  color: #868686;
  margin-left: 6px;
  font-weight: 700;
`;

const TextContainer = styled.div`
  display: flex;
`;
interface AutoCompletedFieldProps {
  field: string;
  information: string;
}

const AutoCompletedField = ({ field, information }: AutoCompletedFieldProps) => (
  <TextContainer>
    <p>{field}:</p>
    <StyledTypography>{information}</StyledTypography>
  </TextContainer>
);

interface Props {
  name: string;
  phone: string;
  email: string;
  faculty: string;
  major: string;
  yearOfStudy: string;
  highschool: string;
  highschoolMajor: string;
}

export const AutoCompletedInfo = ({
  name,
  phone,
  email,
  faculty,
  major,
  yearOfStudy,
  highschool,
  highschoolMajor,
}: Props) => (
  <StyledDiv>
    <h2>{name}</h2>
    <h4>Informatii personale</h4>
    <Spacer />
    <AutoCompletedField field="Telefon" information={phone} />
    <AutoCompletedField field="Email" information={email} />
    <h4>Studii</h4>
    <Spacer />
    <AutoCompletedField field="Facultatea" information={faculty} />
    <AutoCompletedField field="Specializarea" information={major} />
    <AutoCompletedField field="Anul" information={yearOfStudy} />
    <AutoCompletedField field="Liceul absolvit" information={highschool} />
    <AutoCompletedField field="Specializarea liceului absolvit" information={highschoolMajor} />
    <h4>Despre mine</h4>
    <Spacer />
  </StyledDiv>
);
