import React from 'react';
import TextField from '@mui/material/TextField';
import { experimentalStyled as styled } from '@mui/material/styles';
import styledComp from 'styled-components';
import { makeStyles } from '@mui/styles';

const useHelperTextStyles = makeStyles(() => ({
  root: {},
}));

const StyledTextInput = styled(TextField)`
  text-transform: none;
  width: 100%;
  height: 60px;
  font-family: 'Poppins';
  font-size: 20px !important;

  .MuiOutlinedInput-root {
    border: none;
  }

  &:focus {
    border-color: var(--primary);
  }
`;

const StyledContainer = styledComp.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

interface TextInputProps {
  value: string;
  onChange: (event: any) => void;
  label: string;
  type?: string;
  error?: any;
  errorText?: string;
  helperText?: string;
  multiline?: boolean;
  rows?: number;
  name?: string;
  required?: boolean;
  onBlur?: (event: any) => void;
}

export const TextInput = ({
  value,
  onChange,
  label,
  type,
  error,
  helperText,
  multiline,
  rows,
  name,
  required,
  onBlur,
}: TextInputProps) => {
  const helperTextStyles = useHelperTextStyles();

  return (
    <StyledContainer>
      <StyledTextInput
        multiline={multiline}
        value={value}
        onChange={onChange}
        label={label}
        variant="outlined"
        type={type}
        error={error}
        helperText={error}
        rows={rows}
        name={name}
        required={required}
        onBlur={onBlur}
        FormHelperTextProps={{
          classes: {
            root: helperTextStyles.root,
          },
        }}
      />
    </StyledContainer>
  );
};

TextInput.defaultProps = {
  type: 'contained',
  error: false,
  multiline: false,
  helperText: '',
  required: false,
  onBlur: () => null,
};
