import { ApiConfig } from './ApiConfig';

export class ApplicantFileApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  listByCvId(id: string) {
    return this.apiConfig.sendRequest('GET', `/file/applicant/cv/${id}`, null);
  }
}
