import React from 'react';
import styled from 'styled-components';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { Filters } from 'constants/style/Colors';
import { useStore } from 'hooks/useStore';
import { IconButton } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import axios from 'axios';
import { toast } from 'react-toastify';

import PrimaryButton from '../../components/buttons/PrimaryButton';

const IconTextGroup = styled.div`
  display: flex;
  align-items: center;
`;

const SpacerD = styled.div`
  height: 32px;
`;

const Spacer = styled.div`
  height: 32px;
  margin-bottom: 16px;
  border-bottom: 1px solid #ebebeb;
`;

const ContainerWithBorder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFileIcon = styled(InsertDriveFileTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Blue};
`;

const IconTextButton = styled(IconButton)`
  display: flex;
`;

const StyledUpload = styled(FileUploadIcon)`
  margin-right: 8px;
  font-size: 2px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTypography = styled.p`
  font-size: 12px;
`;

interface Props {
  title: string;
  uploadButtonText: string;
  hiddenInput: any;
  file: any;
  internshipId: string;
  studentFileType: string;
  courseId: number;
  handleButtonClick: () => void;
  setFile: (file: any) => void;
}

export const FileUpload = ({
  title,
  uploadButtonText,
  hiddenInput,
  file,
  internshipId,
  studentFileType,
  courseId,
  handleButtonClick,
  setFile,
}: Props) => {
  const {
    cvStore: { getByStudentId },
  } = useStore();
  return (
    <>
      <ContainerWithBorder>
        <h2>{title}</h2>
        <IconTextButton onClick={handleButtonClick}>
          <StyledUpload fontSize="medium" />
          <StyledTypography>ÎNCARCĂ {uploadButtonText}</StyledTypography>
        </IconTextButton>
        <input
          ref={hiddenInput}
          style={{ display: 'none' }}
          type={'file'}
          onChange={(event) => {
            if (event.target.files) {
              setFile(event.target.files[0]);
            }
          }}
        />
      </ContainerWithBorder>
      <Spacer />
      <Row>
        <IconTextGroup>
          <StyledFileIcon fontSize="medium" />
          {file && (
            <a
              href={`${process.env.REACT_APP_API_URL}/file/download/${file.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {file.name || ''}
            </a>
          )}
        </IconTextGroup>

        <PrimaryButton
          full={false}
          onClick={async () => {
            const data = new FormData();
            data.append('file', file);
            const lsUser = localStorage.getItem('user');

            try {
              if (lsUser) {
                const user = JSON.parse(lsUser);

                const cvResponse = await getByStudentId(parseInt(user.student.id));

                if (cvResponse.status !== 200) return toast.error('Completarea cv-ului este obligatorie!');
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/file/`, data, {});
                const studFile = await axios.post(`${process.env.REACT_APP_API_URL}/file/student_file/`, {
                  studentId: parseInt(user.student.id),
                  fileId: response.data.id,
                  filename: file.name,
                  cvId: cvResponse.data._id,
                  courseId: courseId,
                  internshipId: internshipId,
                  type: studentFileType,
                });
                if (studFile.status === 201) {
                  toast.success(`${title} incarcat cu succes!`);
                  setFile({ name: file.name, id: response.data.id });
                }
              }
            } catch (e) {
              console.log(e);
              toast.error('A aparut o problema. Va rugam incercati mai tarziu!');
            }
          }}
        >
          salveaza
        </PrimaryButton>
      </Row>
      <SpacerD />
    </>
  );
};
