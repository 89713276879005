const DashboardRoutes = {
  Dashboard: '/dashboard',
  DashboardOverview: '/dashboard/overview',
  DashboardCompanies: '/dashboard/companies',
  DashboardInternships: '/dashboard/internships',
  DashboardAddInternship: '/dashboard/add_internship',
  DashboardStudentsList: '/dashboard/students_list',
  DashboardApplicants: '/dashboard/applicants',
  StudentsRequestHelp: '/dashboard/studentshelp',
};

export const Routes = {
  Register: '/register',
  Login: '/login',
  ...DashboardRoutes,
  Home: '/',
  Internship: '/internship/:id',
  Profile: '/profile',
  MyInternship: '/my_internship/:id',
  MyInternships: '/my_internships',
  AutoLogin: '/login_auto/:token',
};
