import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles({
  root: (props: { colors?: any }) => ({
    width: '100%',
    borderRadius: '4px',

    '& .MuiTableCell-paddingNone:last-child': {
      padding: '16px',
    },
  }),

  cell: () => ({
    padding: '16px',

    border: 'none',
    borderBottom: `1px dotted lightgrey`,
  }),
});

interface CaseStudyTableProps {
  tableHeaderColumnNames: {
    name: string;
    width: number;
    align?: any;
  }[];
  colors?: any;
}

const AppTable: React.FC<CaseStudyTableProps> = ({ colors, tableHeaderColumnNames, children }) => {
  const classes = useStyles({ colors });

  return (
    <TableContainer className={classes.root}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaderColumnNames.map((column, index) => (
              <TableCell
                className={classes.cell}
                key={`${column.name}_${index}`}
                padding={'none'}
                style={{ width: `${column.width}%` }}
                align={column.align}
              >
                {column.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody style={{ position: 'relative' }}>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppTable;
