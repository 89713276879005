import { createTheme } from '@mui/material/styles';
import { Colors, StatusColors } from '../constants/style/Colors';

export const theme = createTheme({
  palette: {
    success: {
      main: StatusColors.Success,
      light: StatusColors.SuccessBg,
    },
    error: {
      main: Colors.Danger,
    },
  },
});
