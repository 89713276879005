/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { experimentalStyled as MUIstyled } from '@mui/material/styles';
import { Paper } from '@mui/material';
import { observer } from 'mobx-react-lite';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { DOMAINS } from '../../constants/domains/Domains';
import { Groups } from 'constants/enums/Groups';

import { AppBar } from '../../components/navigation/AppBar';
import { InternshipCard } from '../../components/internship/InternshipCard';
import { CategoryFilter } from '../../components/control/CategoryFilter';
import { SearchComponent } from '../../components/internship/SearchComponent';
import { Footer } from '../../components/footer/Footer';
import { useStore } from 'hooks/useStore';
import { InternshipDocument } from 'models/Internship';

const CenterContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Col = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${ScreenSize.mediumSmall} {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const Row = styled.div`
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${ScreenSize.mediumSmall} {
    width: 100%;
    padding: 0px 0px;
  }
`;

const StyledPaper = MUIstyled(Paper)`
  padding: 0px 32px 0px 32px;
  max-height: 66vh;
  overflow: auto;
  box-shadow: none;
  ${ScreenSize.mediumSmall} {
    width: 100%;
    padding: 0px 0px;
  }
`;

const InternshipsScreen = () => {
  const [search, setSearch] = useState('');
  const [userFaculty, setUserFaculty] = useState({});
  const [domains, setDomains] = useState<any>([]);
  const [internshipsList, setInternships] = useState<InternshipDocument[]>([]);
  const {
    internshipStore: { list, internships },
  } = useStore();

  useEffect(() => {
    (async () => {
      const lsUser = localStorage.getItem('user');
      const obj: any = {};

      if (lsUser) {
        const user = JSON.parse(lsUser);
        if (user.user_groups.includes(Groups.Student)) {
          obj[`${user.student.id_facultate}`] = user.student.den_facultate;
          setUserFaculty(obj);
        } else {
          setUserFaculty(user.facultatea);
        }

        console.log('OBJ', obj);

        const dom = DOMAINS.map((domain) => {
          if (domain.faculty in userFaculty) return domain.domain;
        });

        setDomains(dom);
      }

      await list();
      setInternships(internships);
    })();
  }, []);

  useEffect(() => {
    const filtered = internships.filter((i) => {
      return i.title.toLowerCase().includes(search.toLowerCase());
    });
    console.log(filtered);

    setInternships(filtered);
  }, [search]);

  useEffect(() => {
    setInternships(internships);
    console.log(internships);
  }, [internships]);

  return (
    <CenterContainer>
      <AppBar />
      <Row>
        <CategoryFilter />
        <Col>
          <SearchComponent
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <StyledPaper>
            {internshipsList.map((internship) => {
              return (
                <InternshipCard
                  title={internship.title}
                  company={internship.companyId.name}
                  description={internship.companyId.description}
                  logo={internship.companyId.logoUrl}
                  seats={parseInt(internship.maxPositions)}
                  applicants={
                    internship.applicants.length +
                    internship.acceptedApplicants.length +
                    internship.declinedApplicants.length
                  }
                  key={internship._id}
                  to={'internship/' + internship._id}
                  startDate={new Date(internship.startDate).toLocaleDateString('ro-RO')}
                  endDate={new Date(internship.endDate).toLocaleDateString('ro-RO')}
                />
              );
            })}
          </StyledPaper>
        </Col>
      </Row>
      <Footer fixed />
    </CenterContainer>
  );
};

export default observer(InternshipsScreen);
