import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { IoIosArrowForward } from 'react-icons/io';

import { Colors } from '../../constants/style/Colors';
import { Routes } from '../../constants/routes';

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`;

const Main = styled.h3`
  font-weight: normal;
  text-transform: capitalize;
  color: #000;
  margin-right: 8px;
  padding: 0;
`;

const Text = styled.h4`
  font-weight: normal;
  margin-left: 9px;
  color: ${Colors.Grey};
  text-transform: capitalize;
`;

export const Breadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);
  const { pathname } = useLocation();

  useEffect(() => {
    switch (pathname) {
      case Routes.DashboardInternships:
        return setBreadcrumbs(['Dashboard', 'Programe Practică']);
      case Routes.DashboardAddInternship:
        return setBreadcrumbs(['Dashboard', 'Adaugă Program Practică']);
      case Routes.DashboardCompanies:
        return setBreadcrumbs(['Dashbaord', 'Listă AAME']);
      case Routes.DashboardStudentsList:
        return setBreadcrumbs(['Dasbboard', 'Listă Studenți']);
      case Routes.DashboardApplicants:
        return setBreadcrumbs(['Dasborad', 'Aplicanți']);
      case Routes.StudentsRequestHelp:
        return setBreadcrumbs(['Dasborad', 'Raport studenti cerere ajutor']);
    }
  }, [pathname]);

  return (
    <StyledContainer>
      {breadcrumbs.map((b: string, index: number) => {
        if (index === 0)
          return (
            <React.Fragment key={b}>
              <Main>{b}</Main>
              {index !== breadcrumbs.length - 1 && <IoIosArrowForward color={Colors.Grey} size={20} />}
            </React.Fragment>
          );
        return (
          <React.Fragment key={b}>
            <Text>{b}</Text>
            {index !== breadcrumbs.length - 1 && <IoIosArrowForward color={Colors.Grey} size={20} />}
          </React.Fragment>
        );
      })}
    </StyledContainer>
  );
};
