import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import { useFormik } from 'formik';
import { useHistory } from 'react-router';

import { Colors } from '../../constants/style/Colors';
import { ScreenSize } from 'constants/responsive/MediaQueries';
import { AddInternshipSchema } from '../../constants/yup/AddInternshipSchema';
import { Routes } from 'constants/routes';
import { DOMAINS } from 'constants/domains/Domains';
import { FACULTIES } from 'constants/faculties/Faculties';

import { Spacer } from '../common/Spacer';
import { useStore } from '../../hooks/useStore';
import { TinyEditor } from '../control/inputs/Tiny';
import { SmallHeadline } from '../typography/Headlines';
import { AppDatePicker } from '../control/inputs/DatePicker';
import PrimaryButton from '../buttons/PrimaryButton';
import { SelectInput } from 'components/control/inputs/SelectInput';
import { TextInput } from 'components/control/TextInput';

const StyledContainer = styled.form`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: ${Colors.White};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.01);
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 64px;

  ${ScreenSize.mediumSmall} {
    flex-direction: column;
  }
`;

const Group = styled.div`
  flex: 1;
  ${ScreenSize.mediumSmall} {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const SectionTitle = styled.span`
  width: 30%;
  ${ScreenSize.medium} {
    display: none;
  }
`;

interface Option {
  value: string;
  text: string;
}

interface Props {
  isEdit?: boolean;
  internshipId?: string | undefined | null;
}

const AddInternshipForm = ({ isEdit, internshipId }: Props) => {
  const [description, setDescription] = useState('');
  const [descritpionCopy, setDescriptionCopy] = useState('');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [domains, setDomains] = useState<Option[]>([]);

  const {
    authStore: { user },
    internshipStore: { add, update, getOne, current },
  } = useStore();

  const history = useHistory();

  const helperDomains = () => {
    const domains: Option[] = [];
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      const facultiesIDs = FACULTIES.map((fac) => {
        if (user.faculties.includes(fac.name)) return fac.id;
        return null;
      });
      DOMAINS.forEach((domain) => {
        if (facultiesIDs.includes(domain.faculty))
          domains.push({ value: domain.domain, text: domain.domain });
      });
      return domains;
    } else return [{ value: '', text: '' }];
  };

  useEffect(() => {
    (async () => {
      if (isEdit && internshipId) {
        const res = await getOne(internshipId);
        setStartDate(current.startDate);
        setEndDate(current.endDate);
        setDescription(res.data.description);
        setDescriptionCopy(res.data.description);
      }
    })();
    const dom = helperDomains();
    setDomains(dom);
  }, [internshipId]);

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: isEdit ? current.title : '',
      domain: isEdit ? current.domain : '',
      maxPositions: isEdit ? current.maxPositions : '',
      location: isEdit ? current.location : '',
    },
    validationSchema: AddInternshipSchema,
    onSubmit: async (values, { resetForm }) => {
      if (isEdit) {
        await update(internshipId!, { ...values, startDate, endDate, description });
        toast.success('Program actualizat cu succes!', {
          onClose: () => history.push(Routes.DashboardInternships),
        });
      } else {
        if (startDate && endDate) {
          const res = await add({ companyId: user._id, ...values, endDate, startDate, description });
          if (res.status === 201) {
            toast.success('Programul de practica a fost creat!', {
              onClose: () => history.push(Routes.DashboardInternships),
            });
          } else {
            toast.error('A aparut o problema, incercati mai tarziu!', {
              onClose: () => history.push(Routes.DashboardInternships),
            });
          }

          resetForm();
          setEndDate(null);
          setStartDate(null);
          setDescription('');
        }
      }
    },
  });

  return (
    <>
      <ToastContainer />
      <StyledContainer onSubmit={handleSubmit}>
        <SmallHeadline>Adaugati program practica</SmallHeadline>
        <Spacer height={64} />
        <Row>
          <SectionTitle>Informatii generale</SectionTitle>
          <Group>
            <TextInput
              name={'title'}
              value={values.title}
              onChange={handleChange}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title ? errors.title : ''}
              label={'Titlu Practica'}
            />
          </Group>
          <Spacer width={24} />
          <Group>
            <SelectInput
              label="Domeniu"
              labelId="label-domain"
              id="domain"
              name={'domain'}
              value={values.domain}
              required
              error={Boolean(touched.domain && errors.domain)}
              onChange={handleChange}
              helperId="select-helper-text-level"
              helperText={touched.domain && errors.domain ? errors.domain : ''}
              menuOptions={domains}
            />
          </Group>
          <Spacer width={24} />
          <Group>
            <TextInput
              name={'maxPositions'}
              value={values.maxPositions}
              error={Boolean(touched.maxPositions && errors.maxPositions)}
              helperText={touched.maxPositions && errors.maxPositions ? errors.maxPositions : ''}
              onChange={handleChange}
              label={'Nr. maxim pozitii'}
            />
          </Group>
        </Row>

        <Row>
          <SectionTitle>Adresa și perioada de practica</SectionTitle>
          <Group>
            <TextInput
              name={'location'}
              value={values.location}
              error={Boolean(touched.location && errors.location)}
              helperText={touched.location && errors.location ? errors.location : ''}
              onChange={handleChange}
              label={'Adresa unde are loc practica'}
            />
          </Group>
          <Spacer width={24} />
          <Group>
            <AppDatePicker
              label={'Incepand din'}
              onChange={(newDate) => {
                setStartDate(newDate);
              }}
              value={startDate}
            />
          </Group>
          <Spacer width={24} />
          <Group>
            <AppDatePicker label={'Pana in'} onChange={(newDate) => setEndDate(newDate)} value={endDate} />
          </Group>
        </Row>
        <Row>
          <SectionTitle>Detalii program</SectionTitle>
          <TinyEditor
            initialValue={isEdit ? descritpionCopy : ''}
            onChange={(newValue) => {
              setDescription(newValue);
            }}
          />
        </Row>
        <PrimaryButton onClick={handleSubmit}>
          {isEdit ? 'salveaza schimbari' : 'salveaza program'}
        </PrimaryButton>
      </StyledContainer>
    </>
  );
};

export default observer(AddInternshipForm);
