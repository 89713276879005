import React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Drawer from '@mui/material/Drawer';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from 'react-router';

import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Colors } from '../../constants/style/Colors';
import { Routes } from 'constants/routes';

import { useStore } from 'hooks/useStore';

const DrawerHeader = styled.div`
  display: none;

  ${ScreenSize.mediumSmall} {
    padding: 16px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const DrawerList = styled.ul`
  height: 30%;
  width: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
`;

const DrawerLi = styled.li`
  margin-left: 0px;
  margin-bottom: 16px;
  font-size: 18px;
  display: flex;
  align-items: center;
`;

const StyledAnchor = styled.a`
  height: auto;
  min-width: auto;
  padding: 12px 6px;
  text-decoration: none;
  color: #000;
  font-weight: 600;
  transition: color 0.5s ease-out;

  &:hover {
    color: ${Colors.Primary};
  }
`;

interface AppDrawerProps {
  open: boolean;
  handleDrawerClose: () => void;
}

export const AppDrawer = ({ handleDrawerClose, open }: AppDrawerProps) => {
  const { authStore } = useStore();
  const history = useHistory();
  return (
    <Drawer
      onClose={handleDrawerClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '240px',
        },
      }}
      variant="temporary"
      anchor="right"
      open={open}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon style={{ height: '64px', color: 'black' }} />
        </IconButton>
      </DrawerHeader>
      <DrawerList>
        <DrawerLi>
          <StyledAnchor href="/">Descopera programe</StyledAnchor>
        </DrawerLi>
        <DrawerLi>
          <StyledAnchor href="/my_internships">Practica mea</StyledAnchor>
        </DrawerLi>
        <DrawerLi>
          <StyledAnchor href="/profile">Contul meu</StyledAnchor>
        </DrawerLi>
        <DrawerLi
          onClick={() => {
            authStore.logout();
            history.push(Routes.Login);
          }}
        >
          <StyledAnchor href="/profile">Logout</StyledAnchor>
          <LogoutIcon />
        </DrawerLi>
      </DrawerList>
    </Drawer>
  );
};
