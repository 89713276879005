import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Switch, Route, Redirect } from 'react-router';

import { Routes } from '../../constants/routes';
import { Colors } from '../../constants/style/Colors';
import { Groups } from '../../constants/enums/Groups';

import DashboardDrawer from '../../components/drawer/DashboardDrawer';
import AddInternshipScreen from './screens/DashboardAddInternshipScreen';
import DashboardInternshipsPage from './screens/DashboardInternships';
import { DashboardHomeScreen } from './screens/DashboardHomeScreen';
import { DashboardAppBar } from '../../components/navigation/dashboard/AppBar';
import DashboardCompaniesListScreen from './screens/DashboardCompaniesListScreen';
import DashboardInternshipDetails from './screens/DashboardInternshipDetails';
import DashboardStudentsListScreen from './screens/DashboardStudentsListScreen';
import DashboardApplicantsListScreen from './screens/DashboardApplicantsListScreen';
import StudentsRequestHelpScreen from './screens/StudentsRequestHelpScreen';

const StyledContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
`;

const PagesContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${Colors.PrimaryBackground};
`;

const ScreensContainer = styled.div`
  padding: 32px;
  overflow: auto;
`;

const DashboardPages = () => {
  const [userGroups, setUserGroups] = useState<number[]>([]);

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      console.log(JSON.parse(lsUser).user_groups);
      setUserGroups(JSON.parse(lsUser).user_groups);
    }
  }, []);

  return (
    <StyledContainer>
      <DashboardDrawer />
      <PagesContainer>
        <DashboardAppBar />
        <ScreensContainer>
          <Switch>
            <Route path={Routes.DashboardOverview} component={DashboardHomeScreen} />

            {userGroups.includes(Groups.Company) && (
              <>
                <Route
                  path={`${Routes.DashboardInternships}/details/:id`}
                  component={DashboardInternshipDetails}
                />
                <Route path={Routes.DashboardAddInternship} component={AddInternshipScreen} />
                <Route exact path={Routes.DashboardInternships} component={DashboardInternshipsPage} />
                <Route path={Routes.DashboardApplicants} component={DashboardApplicantsListScreen} />
              </>
            )}

            {userGroups.includes(Groups.Dean) && (
              <Route path={Routes.DashboardCompanies} component={DashboardCompaniesListScreen} />
            )}

            {(userGroups.includes(Groups.Dean) || userGroups.includes(Groups.Professor)) && (
              <Route path={Routes.DashboardStudentsList} component={DashboardStudentsListScreen} />
            )}

            {userGroups.includes(Groups.Professor) && (
              <Route path={Routes.StudentsRequestHelp} component={StudentsRequestHelpScreen} />
            )}

            {/* <Redirect exact from={Routes.Dashboard} to={Routes.DashboardOverview} /> */}
          </Switch>
        </ScreensContainer>
      </PagesContainer>
    </StyledContainer>
  );
};
export default DashboardPages;
