import React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import { useHistory } from 'react-router';

import Logo from '../../assets/images/logo_arad.png';
import { ScreenSize } from '../../constants/responsive/MediaQueries';
import { Colors } from '../../constants/style/Colors';
import { Routes } from 'constants/routes';

import { AppDrawer } from './AppDrawer';
import { useStore } from 'hooks/useStore';

const Wrapper = styled.div<{ fixed?: boolean }>`
  width: 100vw;
  display: flex;
  border-bottom: 1px solid #e4e6ef;
  position: ${(props) => (props.fixed ? 'fixed' : 'relative')};
  top: 0;
  background-color: #fff;
  z-index: 2;
`;

const StyledContainer = styled.div`
  height: 100px;
  width: 100%;
  padding: 16px 32px 0px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${ScreenSize.mediumSmall} {
    padding: 16px 16px 0px 46px;
  }
`;

const StyledLogo = styled.img`
  height: 100%;
  width: auto;
  ${ScreenSize.mediumSmall} {
    margin: auto;
  }
`;
const NavigationContainer = styled.ul`
  height: 100%;
  width: auto;
  margin-left: auto;
  margin-right: 0;
  list-style-type: none;
  display: flex;
  align-items: center;

  ${ScreenSize.mediumSmall} {
    display: none;
  }
`;

const StyledLi = styled.li`
  margin-left: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

const StyledAnchor = styled.a`
  height: auto;
  padding: 12px 6px;
  text-decoration: none;
  color: #000;
  font-weight: 500;
  transition: color 0.5s ease-out;

  &:hover {
    color: ${Colors.Primary};
  }
`;

const MenuWrapper = styled.div`
  display: none;

  ${ScreenSize.mediumSmall} {
    display: block;
  }
`;

interface Props {
  fixed?: boolean;
}

export const AppBar = ({ fixed }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { authStore } = useStore();
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Wrapper fixed={fixed}>
      <StyledContainer>
        <StyledLogo src={Logo} />
        <NavigationContainer>
          <StyledLi>
            <StyledAnchor href="/">Descopera programe</StyledAnchor>
          </StyledLi>
          <StyledLi>
            <StyledAnchor href="/my_internships">Practica mea</StyledAnchor>
          </StyledLi>
          <StyledLi>
            <StyledAnchor href="/profile">Contul meu</StyledAnchor>
          </StyledLi>
          <StyledLi
            onClick={() => {
              authStore.logout();
              history.push(Routes.Login);
            }}
          >
            <StyledAnchor href="/profile">Logout</StyledAnchor>
            <LogoutIcon />
          </StyledLi>
        </NavigationContainer>
        <MenuWrapper>
          <IconButton size="large" edge="start" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
        </MenuWrapper>
      </StyledContainer>
      <AppDrawer handleDrawerClose={handleDrawerClose} open={open} />
    </Wrapper>
  );
};

/*

*/
