import React from 'react';
import styled from 'styled-components';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';

import { Filters } from '../../constants/style/Colors';
import { ScreenSize } from '../../constants/responsive/MediaQueries';

const StyledContainer = styled.div`
  display: flex;
  position: fixed;
  transform: translate3d(30vw, 0px, 0px);
  flex-direction: column;
  align-items: center;
  padding: 0px 16px 16px 16px;
  width: 20%;
  height: fit-content;
  background-color: #f6f9fb;

  ${ScreenSize.mediumSmall} {
    position: relative;
    transform: none;
    width: 100%;
  }
`;

const StyledLogo = styled.img`
  max-width: 80px;
  width: auto;
  display: block;

  ${ScreenSize.mediumSmall} {
    max-width: 126px;
  }
`;

const MainInfo = styled.div`
  height: 256px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 32px;
  border-bottom: 1px solid black;
  text-align: center;
`;

const IconInfo = styled.div`
  display: flex;
  align-items: center;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  padding: 16px;
  background-color: #fff;
`;

const StyledAnchor = styled.a`
  height: auto;
  min-width: 120px;
  padding: 12px 6px;
  text-decoration: none;
  color: #868686;
  font-weight: 500;
  transition: color 0.5s ease-out;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);
  }
`;

const StyledTypography = styled.p`
  color: #868686;
`;

const StyledLocationIcon = styled(LocationOnTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledWebIcon = styled(LanguageTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;
const StyledPersonionIcon = styled(PersonOutlineTwoToneIcon)`
  margin-right: 8px;
  filter: ${Filters.Icon};
`;

interface CompanyInfoProps {
  name: string;
  logoSrc: string;
  address: string;
  website: string;
  contacts: string[];
}

export const CompanyInfo = ({ name, logoSrc, address, website, contacts }: CompanyInfoProps) => (
  <StyledContainer>
    <MainInfo>
      <StyledLogo src={logoSrc} alt="Logo wcd" />
      <h3>{name}</h3>
    </MainInfo>
    <ContactInfo>
      <IconInfo>
        <StyledLocationIcon fontSize="medium" />
        <p>Adresa</p>
      </IconInfo>
      <StyledTypography>{address}</StyledTypography>
      <IconInfo>
        <StyledWebIcon fontSize="medium" />
        <p>Website</p>
      </IconInfo>
      <StyledAnchor>{website}</StyledAnchor>
      <IconInfo>
        <StyledPersonionIcon fontSize="medium" />
        <p>Persoane de contact</p>
      </IconInfo>
      {contacts.map((contact, index) => (
        <StyledTypography key={`contact${index}`}>{contact}</StyledTypography>
      ))}
    </ContactInfo>
  </StyledContainer>
);
