import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { TableRow, TableCell } from '@material-ui/core';

import { Colors } from 'constants/style/Colors';
import { MAJORS } from 'constants/enums/Majors';

import AppTable from 'components/table/AppTable';
import StudentRow from 'components/rows/StudentRow';
import { useStore } from 'hooks/useStore';
import { ProfessorFilters } from 'components/control/ProfessorFilters';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
`;

const WhiteContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${Colors.White};
  padding: 24px;
`;

const TableColums = [
  { name: 'Nume', width: 20 },
  { name: 'Programul de studii', width: 20 },
  { name: 'Anul de studiu ', width: 20 },
  { name: 'Anul universitar ', width: 20 },
  { name: 'Documente ', width: 10 },
];

const DashboardStudentsListScreen = () => {
  const [searchName, setSearchName] = useState('');
  const [universityYear, setUniversityYear] = useState(10);
  const [studyYear, setStudyYear] = useState(1);
  const [majors, setMajors] = useState<any[]>([]);
  const [searchMajors, setSearchMajors] = useState([]);
  const [studentFiles, setStudentFiles] = useState<any>([]);
  const {
    studentFileStore: { listByCourseId, files, loading },
  } = useStore();

  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      const user = JSON.parse(lsUser);
      const userFaculties: number[] = Object.keys(user.facultatea).map((f) => parseInt(f));
      const maj = MAJORS.filter((m) => {
        return userFaculties.includes(m.faculty);
      });
      setMajors(maj);
      (async () => {
        const res = await listByCourseId(parseInt(JSON.parse(lsUser).cursuri[0].curs));
        const studs: any[] = [];
        res.data.forEach((f: any) => {
          const cvIds = studs.map((s: any) => s.cvId._id);
          const index = cvIds.indexOf(f.cvId._id);
          if (index >= 0) {
            if (f.type === 'notebook') studs[index].notebook = f;
            if (f.type === 'project') studs[index].project = f;
            if (f.type === 'report') studs[index].report = f;
          } else {
            studs.push({ cvId: f.cvId, notebook: undefined, project: undefined, report: undefined });
            if (f.type === 'notebook') studs[studs.length - 1].notebook = f;
            if (f.type === 'project') studs[studs.length - 1].project = f;
            if (f.type === 'report') studs[studs.length - 1].report = f;
          }
        });
        setStudentFiles(studs);
        console.log(studs);
      })();
    }
  }, []);

  useEffect(() => {
    let filtered = files.filter((f: any) => {
      for (let i = 0; i < searchMajors.length; i++) if (searchMajors[i] === f.cvId.faculty.major) return true;
      return false;
    });
    if (searchMajors.length === 0) filtered = files;
    filtered = filtered.filter((f: any) => {
      let name = f.cvId.name.toLowerCase();
      name = name.replace(new RegExp(/[àáâăãäå]/g), 'a');
      name = name.replace(new RegExp(/æ/g), 'ae');
      name = name.replace(new RegExp(/ç/g), 'c');
      name = name.replace(new RegExp(/ș/g), 's');
      name = name.replace(new RegExp(/[èéêë]/g), 'e');
      name = name.replace(new RegExp(/[ìíîï]/g), 'i');
      name = name.replace(new RegExp(/ñ/g), 'n');
      name = name.replace(new RegExp(/[òóôõö]/g), 'o');
      name = name.replace(new RegExp(/œ/g), 'oe');
      name = name.replace(new RegExp(/[ùúûü]/g), 'u');
      name = name.replace(new RegExp(/[ýÿ]/g), 'y');
      const nameWords = searchName.toLowerCase().split(' ');
      if (nameWords[0] === '') return true;
      for (let i = 0; i < nameWords.length; i++)
        if (nameWords[i] !== '' && name.includes(nameWords[i])) return true;
      return false;
    });
    filtered = filtered.filter((f: any) => {
      if (f.cvId.universityYear === universityYear) return true;
      return false;
    });
    filtered = filtered.filter((f: any) => {
      if (f.cvId.faculty.yearsOfStudy === studyYear) return true;
      return false;
    });
    setStudentFiles(filtered);
  }, [searchName, universityYear, studyYear, searchMajors]);

  return (
    <StyledContainer>
      <WhiteContainer>
        <ProfessorFilters
          nameValue={searchName}
          nameOnChange={(e) => setSearchName(e.target.value)}
          majorsValue={searchMajors}
          majorsOnChange={setSearchMajors}
          majors={majors}
          universityYear={universityYear}
          universityYearOnChange={(e) => setUniversityYear(e.target.value)}
          studyYear={studyYear}
          studyYearOnChange={(e) => setStudyYear(e.target.value)}
        />
      </WhiteContainer>
      <WhiteContainer>
        <AppTable tableHeaderColumnNames={TableColums}>
          {loading === false ? (
            studentFiles.map((fs: any) => (
              <StudentRow
                internshipId={fs.internshipId}
                cvId={fs.cvId._id}
                key={fs.cvId._id}
                name={fs.cvId.name}
                studyProgram={fs.cvId.faculty.major}
                yearOfStudy={fs.cvId.faculty.yearsOfStudy}
                project={fs.project}
                report={fs.report}
                notebook={fs.notebook}
              />
            ))
          ) : (
            <TableRow>
              <TableCell>Loading</TableCell>
            </TableRow>
          )}
        </AppTable>
      </WhiteContainer>
    </StyledContainer>
  );
};

export default observer(DashboardStudentsListScreen);
