import * as Yup from 'yup';

export const AddCVSchema = Yup.object().shape({
  experiences: Yup.array().of(
    Yup.object().shape({
      position: Yup.string().required('Pozitia este obligatorie'),
      company: Yup.string().required('Compania este obligatorie'),
      months: Yup.string().required('Numarul de luni este obligatoriu'),
      type: Yup.string().required('Tipul este obligatoriu'),
    })
  ),
  languages: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required('Limba este obligatorie'),
      level: Yup.string().required('Nivelul este obligatoriu'),
    })
  ),
});
