import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';

import { Routes } from '../constants/routes';

import { verify } from '../services/jwt';
import DashboardPages from '../pages/dashboard/DashboardPages';
import { StudentScreens } from '../pages/student/StudentScreens';
import { Groups } from '../constants/enums/Groups';

const PrivateRoutes = () => {
  const [userGroups, setUserGroups] = useState<number[]>([]);
  const history = useHistory();

  useEffect(() => {
    const lsToken = localStorage.getItem('access_token');
    const lsUser = localStorage.getItem('user');

    try {
      if (!lsToken || !verify(lsToken)) {
        history.push(Routes.Login);
      }

      if (lsUser) {
        const user = JSON.parse(lsUser);
        setUserGroups(user.user_groups);
      } else {
        history.push(Routes.Login);
      }
    } catch (e) {
      history.push(Routes.Login);
    }
  }, []);

  return (
    <Switch>
      <Route path={Routes.Dashboard} component={DashboardPages} />
      {(userGroups.includes(Groups.Dean) || userGroups.includes(Groups.Student)) && (
        <Route path={Routes.Home} component={StudentScreens} />
      )}
    </Switch>
  );
};

export default observer(PrivateRoutes);
