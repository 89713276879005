import React from 'react';
import Button from '@mui/material/Button';

interface Props {
  children: any;
  onClick: () => void;
  icon?: any;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | undefined;
}

export const TextButton = ({ children, onClick, icon, color }: Props) => {
  return (
    <Button variant="text" onClick={onClick} color={color} startIcon={icon}>
      {children}
    </Button>
  );
};
