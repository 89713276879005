import React, { useEffect } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { Colors } from 'constants/style/Colors';

import AppTable from 'components/table/AppTable';
import CompanyRow from 'components/rows/CompanyRow';
import { useStore } from 'hooks/useStore';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  margin: auto;
`;

const WhiteContainer = styled.div`
  width: 100%;
  border-radius: 8px;
  background: ${Colors.White};
  padding: 24px;
`;

const TableColums = [
  { name: 'Nume', width: 20 },
  { name: 'Website', width: 20 },
  { name: 'Email', width: 20 },
  { name: 'Status ', width: 20 },
  { name: 'Detalii ', width: 10 },
];

const DashboardCompaniesListScreen = () => {
  const {
    companyStore: { list, companies },
  } = useStore();

  useEffect(() => {
    (async () => {
      await list();
    })();
  }, []);

  return (
    <StyledContainer>
      <WhiteContainer>
        <AppTable tableHeaderColumnNames={TableColums}>
          {companies.map((c) => (
            <CompanyRow
              id={c._id}
              key={c._id}
              websiteUrl={c.websiteUrl}
              established={c.established}
              registrationCode={c.registrationCode}
              fiscalCode={c.fiscalCode}
              address={c.address}
              phone={c.phone}
              status={c.status}
              name={c.name}
              email={c.email}
              description={c.description}
              contactPersons={c.contacts}
            />
          ))}
        </AppTable>
      </WhiteContainer>
    </StyledContainer>
  );
};

export default observer(DashboardCompaniesListScreen);
