import React from 'react';
import IconButton from '@mui/material/IconButton';

interface Props {
  icon: any;
  onClick: (e: any) => void;
  color?: 'default' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
}

export default function IconButtons({ icon, onClick, color }: Props) {
  return (
    <IconButton color={color || 'primary'} onClick={onClick} aria-label="delete">
      {icon}
    </IconButton>
  );
}
