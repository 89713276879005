import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

interface MultipleValuesPorps {
  placeholder: string;
  label: string;
  onChange: (event: any, value: string[]) => void;
}

export const MultipleValues = ({
  placeholder,
  label,
  onChange,
}: MultipleValuesPorps) => {
  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      style={{ width: '100%' }}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
    />
  );
};
