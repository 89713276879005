import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Divider } from '@mui/material';
import { useHistory, useLocation } from 'react-router';

import { Colors, DashboardAppBarColors, DrawerColors } from '../../../constants/style/Colors';
import { Routes } from '../../../constants/routes';

import { Breadcrumbs } from '../../breadcrumbs/Breadcrumbs';
import { verify } from '../../../services/jwt';
import { Groups } from '../../../constants/enums/Groups';

const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.White};
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.01);
  position: relative;
  z-index: 2;
  /* position: absolute;
  top: 0; */
`;

const AppBarContainer = styled.div`
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 32px;
`;

const BreadcrumbsContainer = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 16px;
`;

const LinkText = styled.div<{ active: boolean }>`
  width: auto;
  height: auto;
  padding: 8px;
  font-size: 16px;
  margin-right: 16px;
  border-radius: 8px;
  text-decoration: none;
  color: ${(props) => (props.active ? Colors.Primary : DrawerColors.FontColor)};
  background-color: ${(props) => props.active && DashboardAppBarColors.ActiveLinkBackground};
  transition: background-color 0.4s;
  cursor: pointer;

  &:hover {
    background-color: ${DashboardAppBarColors.ActiveLinkBackground};
  }
`;

export const DashboardAppBar = () => {
  const location = useLocation();

  const [user, setUser] = useState<any>({ user_groups: [] });
  const history = useHistory();

  useEffect(() => {
    const lsToken = localStorage.getItem('access_token');
    const lsUser = localStorage.getItem('user');
    if (lsToken && lsUser) {
      const payload = verify(lsToken) as any;

      if (!Array.isArray(payload.user_groups)) {
        payload.user_groups = Object.keys(payload.user_groups).map((g) => parseInt(g));
      }

      if (payload.user_groups.includes(Groups.Student)) history.push(Routes.Login);
      setUser(payload);
    }
  }, []);

  return (
    <StyledContainer>
      <AppBarContainer>
        {user.user_groups.includes(Groups.Company) && (
          <LinkText
            active={location.pathname === Routes.DashboardInternships}
            onClick={() => {
              history.push(Routes.DashboardInternships);
            }}
          >
            Programe Practică
          </LinkText>
        )}

        {user.user_groups.includes(Groups.Company) && (
          <LinkText
            active={location.pathname === Routes.DashboardAddInternship}
            onClick={() => {
              history.push(Routes.DashboardAddInternship);
            }}
          >
            Creează un Program de Practică
          </LinkText>
        )}

        {user.user_groups.includes(Groups.Dean) && (
          <LinkText
            active={location.pathname === Routes.DashboardCompanies}
            onClick={() => {
              history.push(Routes.DashboardCompanies);
            }}
          >
            Agenți ai mediului economic
          </LinkText>
        )}

        {user.user_groups.includes(Groups.Professor) && (
          <LinkText
            active={location.pathname === Routes.DashboardStudentsList}
            onClick={() => {
              history.push(Routes.DashboardStudentsList);
            }}
          >
            Listă Studenți
          </LinkText>
        )}

        {user.user_groups.includes(Groups.Professor) && (
          <LinkText
            active={location.pathname === Routes.DashboardStudentsList}
            onClick={() => {
              history.push(Routes.DashboardStudentsList);
            }}
          >
            Raport studenti cerere ajutor
          </LinkText>
        )}
      </AppBarContainer>
      <Divider />
      <BreadcrumbsContainer>
        <Breadcrumbs />
      </BreadcrumbsContainer>
    </StyledContainer>
  );
};
