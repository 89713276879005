import { CVApi } from 'api/CVApi';
import { CVHelpApi } from 'api/CVHelpApi';
import { ReviewApi } from 'api/ReviewApi';
import { ApiConfig } from '../api/ApiConfig';
import { AuthApi } from '../api/AuthApi';
import { CompanyApi } from '../api/CompanyApi';
import { InternshipApi } from '../api/InternshipApi';
import { StudentFileApi } from '../api/StudentFileApi';
import { ApplicantFileApi } from '../api/ApplicantFileApi';

import { getAppVersion } from '../services/info';

export class AppStateStore {
  apiConfig: ApiConfig;
  companyApi: CompanyApi;
  authApi: AuthApi;
  internshipApi: InternshipApi;
  cvApi: CVApi;
  reviewApi: ReviewApi;
  studentFileApi: StudentFileApi;
  cvHelpApi: CVHelpApi;
  applicantFileApi: ApplicantFileApi;

  constructor(apiUrl: string = process.env.REACT_APP_API_URL || 'http://localhost:8081') {
    this.apiConfig = new ApiConfig(apiUrl, `v${getAppVersion()}`);
    this.companyApi = new CompanyApi(this.apiConfig);
    this.authApi = new AuthApi(this.apiConfig);
    this.internshipApi = new InternshipApi(this.apiConfig);
    this.cvApi = new CVApi(this.apiConfig);
    this.reviewApi = new ReviewApi(this.apiConfig);
    this.studentFileApi = new StudentFileApi(this.apiConfig);
    this.cvHelpApi = new CVHelpApi(this.apiConfig);
    this.applicantFileApi = new ApplicantFileApi(this.apiConfig);
  }
}
