import React from 'react';
import { InputBase } from '@mui/material';
import styled from 'styled-components';
import { styled as MUIstyled } from '@mui/material/styles';

const StyledContainer = styled.form`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0px;
`;

const StyledInputBase = MUIstyled(InputBase)`
display: block;
width: 100%;
padding: 0.75rem 1rem;
font-size: 16px;
font-weight: 500;
font-family: Poppins;
line-height: 1.5;
color: #181c32;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #e4e6ef;
border-radius: 0.475rem;
box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%);
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
`;

interface Props {
  value: string;
  onChange: (newValue: any) => void;
}

export const SearchInput = ({ value, onChange }: Props) => (
  <StyledContainer>
    <StyledInputBase value={value} onChange={onChange} placeholder="Cauta" />
  </StyledContainer>
);
