import { ApiConfig } from './ApiConfig';
import { InternshipDto } from '../models/Internship';

const DEFAULT_ENDPOINT = '/internship';

export class InternshipApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  add(data: InternshipDto) {
    return this.apiConfig.sendRequest('POST', DEFAULT_ENDPOINT, data);
  }

  getOne(id: string) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/${id}`, null);
  }

  listByCompany(id: string) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/company/${id}`, null);
  }
  list() {
    return this.apiConfig.sendRequest('GET', DEFAULT_ENDPOINT, null);
  }

  update(id: string, data: Omit<InternshipDto, 'companyId'>) {
    return this.apiConfig.sendRequest('PUT', `${DEFAULT_ENDPOINT}/${id}`, data);
  }

  addApplicant(id: string, applicantId: string) {
    return this.apiConfig.sendRequest('PATCH', `${DEFAULT_ENDPOINT}/${id}/add/${applicantId}`);
  }
  acceptApplicant(id: string, applicantId: string) {
    return this.apiConfig.sendRequest('PATCH', `${DEFAULT_ENDPOINT}/${id}/accept/${applicantId}`);
  }
  declineApplicant(id: string, applicantId: string) {
    return this.apiConfig.sendRequest('PATCH', `${DEFAULT_ENDPOINT}/${id}/decline/${applicantId}`);
  }
  listByCV(id: string) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/cv/${id}`, null);
  }
}
