import * as React from 'react';
import { Modal, Paper, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ApplicantCV } from './ApplicantCV';

const StyledClose = styled(CloseIcon)`
  color: #fff;
`;

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '80vh',
};
interface Props {
  open: boolean;
  handleClose: () => void;
  cv: any;
}
const ApplicantCVModal = ({ open, handleClose, cv }: Props) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div>
          <IconButton sx={{ position: 'absolute', top: '6%', right: '21.5%' }} onClick={handleClose}>
            <StyledClose />
          </IconButton>
          <Paper sx={style}>
            <ApplicantCV cv={cv} />
          </Paper>
        </div>
      </Modal>
    </div>
  );
};

export default observer(ApplicantCVModal);
