import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { MdEmail, MdLocationOn, MdVerified } from 'react-icons/md';
import Alert from '@mui/material/Alert';

import { Colors, StatusColors } from '../../constants/style/Colors';
import { CompanyStatuses } from '../../constants/enums/CompanyStatuses';
import { ScreenSize } from '../../constants/responsive/MediaQueries';

import { Spacer } from '../spacer/Spacer';
import { SmallHeadline } from '../typography/Headlines';
import { GraySubtitle } from '../typography/Subtitles';
import PrimaryButton from '../buttons/PrimaryButton';
import { Action } from '../../constants/types/Action';

const StyledContainer = styled.div`
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.03), 0 3px 6px rgba(0, 0, 0, 0.01);
  background-color: ${Colors.White};
  border-radius: 8px;
  width: 100%;
  min-height: 250px;
  padding: 24px;
`;

const StyledImage = styled.img`
  width: 130px;
  height: auto;
  border-radius: 16px;
  margin-right: 16px;

  ${ScreenSize.small} {
    margin-bottom: 24px;
  }
`;

const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${ScreenSize.small} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${ScreenSize.small} {
    justify-content: center;
    margin-bottom: 8px;
  }
`;

const SpacedRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ScreenSize.small} {
    justify-content: center;
    text-align: center;
  }
`;

const Column = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledAlertContainer = styled.div`
  margin-bottom: 24px;
`;

const SpacerDiv = styled.div`
  flex-grow: 1;

  ${ScreenSize.small} {
    display: none;
  }
`;

const MobileContainer = styled.div`
  ${ScreenSize.small} {
    display: none;
  }
`;

interface Props {
  email: string;
  address: string;
  companyName: string;
  action?: Action;
  logoUrl: string;
}

export const CompanyProfile = ({ email, companyName, address, action, logoUrl }: Props) => {
  const [status, setStatus] = useState('');
  const [showAlert, setShowAlert] = useState(true);
  useEffect(() => {
    const lsUser = localStorage.getItem('user');
    if (lsUser) {
      setStatus(JSON.parse(lsUser).status);
    }
  }, []);

  return (
    <StyledContainer>
      <StyledAlertContainer>
        {showAlert && status === CompanyStatuses.Pending && (
          <Alert
            onClose={() => {
              setShowAlert(false);
            }}
            severity="warning"
          >
            Contul dumneavoastra asteapta aprobarea universității. Drepturile dumneavoastra vor fi limitate
            pana la momentul respectiv.
          </Alert>
        )}
      </StyledAlertContainer>
      <ContainerRow>
        <StyledImage src={logoUrl} />
        <Column>
          <SpacedRow>
            <SmallHeadline>{companyName}</SmallHeadline>
            <Spacer width={8} />
            {status === CompanyStatuses.Accepted && <MdVerified color={StatusColors.Success} size={24} />}
            <SpacerDiv />
            <MobileContainer>
              {action && (
                <PrimaryButton startIcon={action.icon} onClick={action.handler}>
                  {action.title}
                </PrimaryButton>
              )}
            </MobileContainer>
          </SpacedRow>
          <Spacer height={8} />
          <Row>
            <MdEmail color={Colors.Grey} size={16} />
            <Spacer width={8} />
            <GraySubtitle>{email}</GraySubtitle>
          </Row>
          <Row>
            <MdLocationOn color={Colors.Grey} size={16} />
            <Spacer width={8} />
            <GraySubtitle>{address}</GraySubtitle>
          </Row>
        </Column>
      </ContainerRow>
    </StyledContainer>
  );
};
