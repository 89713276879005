import React, { useState } from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  IconButton,
  Select,
  SelectChangeEvent,
  OutlinedInput,
  Box,
  Chip,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import axios from 'axios';

import { REGISTER_FORM_INITIAL_VALUE } from '../../constants/initial/RegisterForm';
import { INITIAL_COMPANY_REGISTRATION } from '../../constants/initial/InitialCompRegistration';
import { RegistrationSchema } from '../../constants/yup/RegistrationSchema';
import { FACULTIES } from 'constants/faculties/Faculties';
import { CompanyStatus } from '../../models/Company';
import { Routes } from '../../constants/routes';
import { Colors } from 'constants/style/Colors';

import PrimaryButton from '../buttons/PrimaryButton';
import { TextInput } from '../control/TextInput';
import { MultipleValues } from '../control/MultipleValues';
import { useStore } from '../../hooks/useStore';
import { Spacer } from '../spacer/Spacer';

const Row = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 24px;
  align-items: center;
  justify-content: space-between;
`;

const Half = styled.div`
  width: 50%;
`;

const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: lighter;
  text-align: left;
  color: lightgrey;
  margin-top: 16px;
  margin-bottom: 0;
`;

const StyledImg = styled.img`
  width: 100px;
  height: auto;
`;

const StyledUpload = styled(FileUploadIcon)`
  margin-right: 8px;
  font-size: 2px;
`;
const StyledTypography = styled.p`
  font-size: 12px;
`;
const IconTextButton = styled(IconButton)`
  display: flex;
`;

const StyledHelper = styled.p`
  font-size: 12px;
  color: ${Colors.Grey};
  margin: 0;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const RegisterForm = () => {
  const [formData, setFormData] = useState(REGISTER_FORM_INITIAL_VALUE);
  const [faculties, setFaculties] = React.useState<string[]>([]);
  const [file, setFile] = useState<any>();
  const [previewSrc, setPreviewSrc] = useState<any>();
  const hiddenFileInput = React.useRef(null);
  const history = useHistory();
  const {
    companyStore: { create },
  } = useStore();

  const handleChangeSelect = (event: SelectChangeEvent<typeof faculties>) => {
    const {
      target: { value },
    } = event;
    setFaculties(typeof value === 'string' ? value.split(',') : value);
  };

  const handleButtonClick = () => {
    /* @ts-ignore */
    hiddenFileInput!.current.click();
  };

  const { values, handleSubmit, handleChange, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: INITIAL_COMPANY_REGISTRATION,
    validationSchema: RegistrationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = new FormData();
      data.append('file', file);

      let response;
      try {
        response = await axios.post(`${process.env.REACT_APP_API_URL}/file/profile_picture`, data, {});
        console.log(response);
      } catch (e) {
        console.log(e);
        toast.error('Alegeti o imagine!');
      }

      create({
        ...omit(formData, ['confirmPassword']),
        status: CompanyStatus.Pending,
        logoUrl: response?.data.image
          ? `${process.env.REACT_APP_API_URL}/${response.data.image}`
          : 'https://www.google.com/imgres?imgurl=https%3A%2F%2Fcathla.org%2Fassets%2Fimages%2Fdefault%2Forganization.png&imgrefurl=https%3A%2F%2Fcathla.org%2FBrooklynLongIsland%2FContact_Management%2FOrganization_Profile.aspx%3FWebsiteKey%3D94672651-b722-440f-a91a-7c4834b23d57&tbnid=khUqr8imDhB_DM&vet=12ahUKEwiUtMX5_eD2AhXcXvEDHR9tDFEQMyhGegQIARBh..i&docid=ZCtm2itspo2OEM&w=250&h=250&itg=1&q=default%20organization%20avatar%20icon%20png&ved=2ahUKEwiUtMX5_eD2AhXcXvEDHR9tDFEQMyhGegQIARBh',
        faculties: faculties,
        ...values,
      });
      history.push(Routes.Login);
    },
  });

  return (
    <>
      <Title>Informatii Generale</Title>
      <Row>
        <IconTextButton onClick={handleButtonClick}>
          <StyledUpload fontSize="medium" />
          <StyledTypography>ÎNCARCĂ LOGO</StyledTypography>
        </IconTextButton>
        <input
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          type={'file'}
          onChange={(event) => {
            if (event.target.files) {
              setFile(event.target.files[0]);

              const src = URL.createObjectURL(event.target.files[0]);
              setPreviewSrc(src);
            }
          }}
        />

        <StyledImg src={previewSrc} />
      </Row>
      <Row>
        <StyledHelper>Toate câmpurile marcate cu * sunt obligatorii</StyledHelper>
      </Row>
      <Row>
        <Half>
          <TextInput
            error={touched.name ? errors.name : null}
            helperText={errors.name}
            name={'name'}
            value={values.name}
            onChange={handleChange}
            label={'Denumire'}
            required
          />
        </Half>
        <Spacer width={16} />
        <Half>
          <TextInput
            error={touched.address ? errors.address : null}
            helperText={errors.address}
            name={'address'}
            value={values.address}
            onChange={handleChange}
            label={'Adresa'}
            required
          />
        </Half>
      </Row>
      <Row>
        <Half>
          <TextInput
            error={touched.fiscalCode ? errors.fiscalCode : null}
            helperText={errors.fiscalCode}
            name={'fiscalCode'}
            value={values.fiscalCode}
            onChange={handleChange}
            label={'Cod Fiscal'}
            required
          />
        </Half>
        <Spacer width={16} />
        <Half>
          <TextInput
            error={touched.registrationCode ? errors.registrationCode : null}
            helperText={errors.registrationCode}
            name={'registrationCode'}
            value={values.registrationCode}
            onChange={handleChange}
            label={'Cod Înregistrare'}
            required
          />
        </Half>
      </Row>
      <Row>
        <Half>
          <TextInput
            error={touched.email ? errors.email : null}
            helperText={errors.email}
            name={'email'}
            value={values.email}
            onChange={handleChange}
            label={'Email'}
            required
          />
        </Half>
        <Spacer width={16} />
        <Half>
          <TextInput
            error={touched.phone ? errors.phone : null}
            helperText={errors.phone}
            name={'phone'}
            value={values.phone}
            onChange={handleChange}
            label={'Nr. Telefon'}
            required
          />
        </Half>
      </Row>
      <Row>
        <Half>
          <TextInput
            error={touched.established ? errors.established : null}
            helperText={errors.established}
            name={'established'}
            value={values.established}
            onChange={handleChange}
            label={'Anul înfințării'}
            required
          />
        </Half>
        <Spacer width={16} />
        <Half>
          <TextInput
            error={touched.websiteUrl ? errors.websiteUrl : null}
            helperText={errors.websiteUrl}
            name={'websiteUrl'}
            value={values.websiteUrl}
            onChange={handleChange}
            label={'Website URL'}
            required
          />
        </Half>
      </Row>
      <Row>
        <MultipleValues
          onChange={(e, value) => {
            setFormData({ ...formData, contacts: value });
          }}
          label={'Persoane de contact *'}
          placeholder={'Introduceti câte o adresă de email și apăsați Enter'}
        />
      </Row>
      <Row>
        <TextInput
          rows={6}
          multiline
          error={touched.description ? errors.description : null}
          helperText={errors.description}
          name={'description'}
          value={values.description}
          onChange={handleChange}
          label={'Descriere'}
          required
        />
      </Row>{' '}
      <Spacer height={112} />
      <Title>Selectați facultățile pentru care doriți să aplicați</Title>
      <InputLabel id="demo-multiple-chip-label">Facultăți *</InputLabel>
      <Select
        multiple
        fullWidth
        value={faculties}
        id="demo-multiple-chip"
        labelId="demo-multiple-chip-label"
        onChange={handleChangeSelect}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {FACULTIES.map((fac) => (
          <MenuItem key={fac.name} value={fac.name}>
            {fac.name}
          </MenuItem>
        ))}
      </Select>
      <Spacer height={32} />
      <Title>Introduceti credențiale</Title>
      <Row>
        <TextInput
          error={touched.password ? errors.password : null}
          helperText={errors.password ? errors.password : 'Exemplu: Parola1234!'}
          name={'password'}
          value={values.password}
          onChange={handleChange}
          label={'Parola'}
          type={'password'}
          required
        />
      </Row>
      <Row>
        <TextInput
          error={touched.confirmPassword ? errors.confirmPassword : null}
          helperText={errors.confirmPassword}
          name={'confirmPassword'}
          value={values.confirmPassword}
          onChange={handleChange}
          label={'Confirmă parola'}
          type={'password'}
          required
        />
      </Row>
      <Spacer height={24} />
      <PrimaryButton
        onClick={() => {
          handleSubmit();
        }}
      >
        Trimite pentru aprobare
      </PrimaryButton>
    </>
  );
};
