import React from 'react';
import { Select, MenuItem, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Colors } from '../../../constants/style/Colors';

const useStyles = makeStyles({
  root: {
    width: '100%',
    '& .MuiFormHelperText-root': {
      color: `${Colors.Danger}`,
    },
  },
});

interface Option {
  value: string;
  text: string;
}

interface SelectInputProps {
  id: string;
  name: string;
  value: string;
  required?: boolean;
  error?: boolean;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  menuOptions: Option[];
  label: string;
  helperText?: string;
  labelId: string;
  helperId: string;
}

export const SelectInput = ({
  id,
  name,
  value,
  required,
  error,
  onChange,
  onBlur,
  menuOptions,
  label,
  helperText,
  labelId,
  helperId,
}: SelectInputProps) => {
  const classes = useStyles();
  return (
    <FormControl className={classes.root}>
      <InputLabel id={labelId} error={error}>
        {label}
      </InputLabel>
      <Select
        label={label}
        labelId={labelId}
        id={id}
        name={name}
        value={value}
        required={required}
        error={error}
        onChange={onChange}
        onBlur={onBlur}
        aria-describedby={helperId}
      >
        {menuOptions.map((option, index) => (
          <MenuItem key={`option-${index}`} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id={helperId}>{helperText}</FormHelperText>
    </FormControl>
  );
};

SelectInput.defaultProps = {
  error: false,
  helperText: '',
  required: false,
  onBlur: () => null,
};
