import { CvDto } from 'models/CV';
import { ApiConfig } from './ApiConfig';

const DEFAULT_ENDPOINT = '/cv';

export class CVApi {
  apiConfig: ApiConfig;

  constructor(apiConfig: ApiConfig) {
    this.apiConfig = apiConfig;
  }

  add(data: CvDto) {
    return this.apiConfig.sendRequest('POST', DEFAULT_ENDPOINT, data);
  }

  update(id: string, data: Omit<CvDto, 'cvId'>) {
    return this.apiConfig.sendRequest('PUT', `${DEFAULT_ENDPOINT}/${id}`, data);
  }
  getByStudentId(studentId: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/student/${studentId}`, null);
  }
  listByCourse(courseId: number) {
    return this.apiConfig.sendRequest('GET', `${DEFAULT_ENDPOINT}/course/${courseId}`, null);
  }
}
