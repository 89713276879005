import { UserRoles } from '../enums/UserRoles';

export enum CompanyStatus {
  Approved = 'approved',
  Pending = 'pending',
  Declined = 'declined',
}
export const INITIAL_COMPANY = {
  _id: '',
  name: '',
  address: '',
  fiscalCode: '',
  registrationCode: '',
  established: '',
  email: '',
  phone: '',
  description: '',
  websiteUrl: '',
  contacts: [],
  password: '',
  status: CompanyStatus.Pending,
  logoUrl: '',
  role: UserRoles.Company,
  faculties: [],
};
