export const FACULTIES = [
  { id: 1, name: 'de Inginerie Alimentară, Turism şi Protecția Mediului' },
  { id: 2, name: 'de Științe Economice' },
  { id: 3, name: 'de Design' },
  { id: 4, name: 'de Educație Fizică şi Sport' },
  { id: 5, name: 'de Ştiințe ale Educației, Psihologie şi Asistență Socială' },
  { id: 6, name: 'de Teologie Ortodoxă “Ilarion V. Felea”' },
  { id: 7, name: 'de Inginerie' },
  { id: 8, name: 'de Ştiințe Exacte' },
  { id: 9, name: 'de Ştiințe Umaniste şi Sociale' },
];
