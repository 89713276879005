import React, { useState } from 'react';
import styled from 'styled-components';

const StyledCheckbox = styled.div<{ selected?: boolean }>`
  border-radius: 6px;
  width: 100%;
  padding: 16px;
  font-size: 12px;
  border-left: ${(props) => (props.selected ? '4px solid#009EF7' : '0')};
  background-color: ${(props) => (props.selected ? '#F6F9FB' : 'none')};
  font-weight: ${(props) => (props.selected ? '600' : '500')};

  &:hover {
    background-color: #f6f9fb;
  }
`;

interface CategoryCheckboxProps {
  children: any;
}

export const CategoryCheckbox = ({ children }: CategoryCheckboxProps) => {
  const [selected, setSelected] = useState(false);

  const handleSelection = () => setSelected((prevState) => !prevState);

  return (
    <StyledCheckbox onClick={handleSelection} selected={selected}>
      {children}
    </StyledCheckbox>
  );
};
