import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';

import { Colors, DrawerColors } from '../../constants/style/Colors';
import { Spacer } from '../spacer/Spacer';

const StyledContainer = styled.div<{ active: boolean; open: boolean }>`
  width: 100%;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.4s;
  padding-left: ${(props) => props.open && '32px'};
  justify-content: ${(props) => (props.open ? 'flex-start' : 'center')};
  background-color: ${(props) => props.active && DrawerColors.BackgroundActive};
  color: ${(props) => (props.active ? Colors.Primary : DrawerColors.IconFontColor)};

  &:hover {
    background-color: ${DrawerColors.BackgroundActive};
    color: ${Colors.Primary};
  }
`;

const Text = styled.p`
  color: ${DrawerColors.FontColor};
  font-size: 14px;
`;

interface Props {
  to: string;
  text: string;
  open: boolean;
  icon: React.ReactNode;
}

const DrawerItem = ({ to, text, icon, open }: Props) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <StyledContainer
      open={open}
      active={location.pathname === to}
      onClick={() => {
        history.push(to);
      }}
    >
      {icon}
      <Spacer width={16} />
      {open && <Text>{text}</Text>}
    </StyledContainer>
  );
};

export default DrawerItem;
