export const REGISTER_FORM_INITIAL_VALUE = {
  name: '',
  address: '',
  fiscalCode: '',
  registrationCode: '',
  established: '',
  email: '',
  phone: '',
  description: '',
  websiteUrl: '',
  contacts: [''],
  password: '',
  confirmPassword: '',
  logoUrl: '',
  faculties: [],
};
